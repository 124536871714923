<template>
  <div class="menu-search" :class="{ mobile: isMobile }">
    <input-text
      v-model="search"
      label="Search..."
      icon="search"
      small
      dark
      @blur="handleBlur"
      @keypress.arrow-down="select(0)"
    />
    <transition name="search">
      <div v-if="isSearched" class="results scroll-area">
        <div v-if="searchResults?.length">
          <menu-search-result-item
            v-for="(item, index) in searchResults"
            :key="index"
            :item="item"
          />
        </div>
        <div v-else class="no-results">No results found</div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import InputText from "~/components/new-platform/forms/InputText.vue";
import MenuSearchResultItem from "./MenuSearchResultItem.vue";

export default {
  components: {
    "input-text": InputText,
    "menu-search-result-item": MenuSearchResultItem
  },

  data: () => ({
    search: ""
  }),

  computed: {
    ...mapState({
      securityList: (state) => state.security.securityList,
      memberList: (state) => state.member.memberList
    }),

    ...mapGetters({
      isMobile: "app_store/isMobile"
    }),

    searchData() {
      const seciurities = this.securityList
        .filter((item) => !!item.value.permission)
        .map((item) => ({
          type: "security",
          text: item.text,
          id: item.value.id
        }));

      const members = this.memberList.map((item) => ({
        type: "member",
        text: item.value.company
          ? `${item.value.name} | ${item.value.company}`
          : `${item.value.name} | -`,
        id: item.value.id
      }));
      return [...seciurities, ...members];
    },

    searchResults() {
      if (!this.search.trim()) return [];

      const search = this.search.toLowerCase();

      return this.searchData.filter((item) =>
        item.text.toLowerCase().includes(search)
      );
    },

    isSearched() {
      return !!this.search.trim();
    }
  },

  methods: {
    async handleBlur() {
      await new Promise((resolve) => setTimeout(resolve, 300));
      this.search = "";
    }
  }
};
</script>

<style lang="sass" scoped>
.search-enter-active
  transition: all 0.2s ease-in-out
  transition-delay: 0.1s
.search-leave-active
  transition-delay: 0s
  transition: all 0.2s ease-in-out

.search-enter
  opacity: 0
  transform: translateY(15px)

.search-leave-to
  opacity: 0
  transform: translateY(15px)

.menu-search
  position: relative
  &.mobile
    .results
      width: calc(100vw - 2 * var(--dashboard-gap))
      max-width: 100vw
  .results
    position: absolute
    top: 40px
    width: 100%
    min-width: 400px
    right: 0
    background-color: #fff
    border-radius: 6px
    padding: 4px
    z-index: 1000
    box-shadow: 0 2px 40px -7px rgba(0, 0, 0, 0.25)
    display: flex
    flex-direction: column
    gap: var(--gap-small)
    max-height: 400px
    overflow-y: auto
    border: 1px solid rgba(0,0,0,0.05)

  .no-results
    padding: calc(var(--dashboard-gap) / 50) 8px
    margin-top: calc(var(--dashboard-gap) / 2)
    color: rgba(0,0,0, 0.7)
    font-size: var(--font-size-small)
    font-style: italic
</style>
