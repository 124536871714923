// for multiple request
let isTokenRefreshing = false;
let tokenFailedRequestQueue = [];
const processTokenFailedQueue = (error, token = null) => {
  tokenFailedRequestQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  tokenFailedRequestQueue = [];
};
export default function (ctx) {
  ctx.$axios.onError((error) => {
    const code = parseInt(error.response && error.response.status);
    const originalRequest = error.config;
    const refreshT = ctx.$auth.$storage.getUniversal("_refresh_token.ranos");
    //console.log(code, originalRequest, refreshT);
    if (code === 417 && !originalRequest._retry && refreshT) {
      if (isTokenRefreshing) {
        return new Promise(function (resolve, reject) {
          tokenFailedRequestQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = `${token}`;
            return ctx.$axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isTokenRefreshing = true;

      return new Promise(function (resolve, reject) {
        ctx.store
          .dispatch("user/refreshToken")
          .then((refreshR) => {
            if (refreshR.action === "accept") {
              originalRequest.headers["Authorization"] = `${refreshR.token}`;
              processTokenFailedQueue(null, refreshR.token);
              resolve(ctx.$axios(originalRequest));
            } else {
              processTokenFailedQueue(true, null);
              reject(refreshR);
            }
          })
          .catch((err) => {
            processTokenFailedQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isTokenRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  });

  ctx.$axios.onResponse((res) => {
    // console.log("res", res);
    // save id_token for hydra
    if (
      res.config.url.indexOf("/oauth2/token") > -1 &&
      res.config.method === "post" &&
      res.data.id_token
    ) {
      ctx.store.$cookies.set("auth._id_token.ranos", res.data.id_token);
    }
    // return res;
  });
}
