import CryptoJS from "crypto-js";
import Vue from "vue";
import Countries from "~/components/new-platform/assets/countries.js";

const clone = require("rfdc")({
  proto: true
});
Number.isInteger =
  Number.isInteger ||
  function (value) {
    return (
      typeof value === "number" &&
      isFinite(value) &&
      Math.floor(value) === value
    );
  };
if (!Number.MAX_SAFE_INTEGER) {
  Number.MAX_SAFE_INTEGER = 9007199254740991; // Math.pow(2, 53) - 1;
}
Number.isSafeInteger =
  Number.isSafeInteger ||
  function (value) {
    return (
      Number.isInteger(value) && Math.abs(value) <= Number.MAX_SAFE_INTEGER
    );
  };
Vue.config.performance = true;
Number.prototype.countDecimals = function () {
  if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
  return this.toString().split(".")[1].length || 0;
};

Vue.mixin({
  methods: {
    doesBrowserSupportPassiveScroll() {
      let passiveSupported = false;

      try {
        const options = {
          get passive() {
            // This function will be called when the browser
            //   attempts to access the passive property.
            passiveSupported = true;
            return false;
          }
        };

        window.addEventListener("test", null, options);
        window.removeEventListener("test", null, options);
      } catch (err) {
        console.error(err);
        passiveSupported = false;
      }
      return passiveSupported;
    },
    deepCopyJson(objectToClone) {
      return clone(objectToClone);
    },
    wait(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getRandomColor(
      rgb = false,
      { darkColor, lightColor, numberOfShades } = {}
    ) {
      let color = null;
      if (rgb) {
        if (darkColor && lightColor && numberOfShades) {
          if (numberOfShades > 1) {
            // different shade of the same color
            const colorIncrement = Math.floor(
              (lightColor[0] - darkColor[0]) / (numberOfShades - 1)
            );
            color = [];
            for (let i = 0; i < numberOfShades; i++) {
              color[i] = "rgb(";
              darkColor.map((number, index) => {
                color[i] += `${number + i * colorIncrement}`;
                if (index != lightColor.length - 1) color[i] += ", ";
                else color[i] += ")";
              });
            }
          } else {
            color = ["rgb(0, 108, 205)"];
          }
        } else {
          // random rgb
        }
      } else {
        const letters = "0123456789ABCDEF";
        color = "#";
        for (let i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
      }

      return color;
    },
    getCaretPosition(editableDiv) {
      let caretPos = 0,
        sel,
        range;
      if (window.getSelection) {
        sel = window.getSelection();
        if (sel.rangeCount) {
          range = sel.getRangeAt(0);
          if (range.commonAncestorContainer.parentNode == editableDiv) {
            caretPos = range.endOffset;
          }
        }
      } else if (document.selection && document.selection.createRange) {
        range = document.selection.createRange();
        if (range.parentElement() == editableDiv) {
          const tempEl = document.createElement("span");
          editableDiv.insertBefore(tempEl, editableDiv.firstChild);
          const tempRange = range.duplicate();
          tempRange.moveToElementText(tempEl);
          tempRange.setEndPoint("EndToEnd", range);
          caretPos = tempRange.text.length;
        }
      }
      return caretPos;
    },
    getCaret(editableDiv) {
      var range = window.getSelection().getRangeAt(0);
      var treeWalker = document.createTreeWalker(
        editableDiv,
        NodeFilter.ELEMENT_NODE,
        function (editableDiv) {
          var nodeRange = document.createRange();
          nodeRange.selectNodeContents(editableDiv);
          return nodeRange.compareBoundaryPoints(Range.END_TO_END, range) < 1
            ? NodeFilter.FILTER_ACCEPT
            : NodeFilter.FILTER_REJECT;
        },
        false
      );
      var charCount = 0,
        lastNodeLength = 0;
      if (range.startContainer.nodeType == 3) {
        charCount += range.startOffset;
      }
      while (treeWalker.nextNode()) {
        charCount += lastNodeLength;
        lastNodeLength = 0;
        if (range.startContainer != treeWalker.currentNode) {
          if (treeWalker.currentNode instanceof Text) {
            lastNodeLength += treeWalker.currentNode.length;
          } else if (
            treeWalker.currentNode instanceof HTMLBRElement ||
            treeWalker.currentNode instanceof HTMLImageElement
          ) {
            lastNodeLength++;
          }
        }
      }
      return charCount + lastNodeLength;
    },
    moveCaretPosition(editableDiv, charCount) {
      let sel, range;
      if (window.getSelection) {
        sel = window.getSelection();
      } else if (document.selection && document.selection.createRange) {
        sel = document.selection;
      }
      if (sel.rangeCount) {
        range = document.createRange();
        var childNodeIndex = 0;
        if (editableDiv.childNodes.length > 1) {
          for (var i = 0; i < editableDiv.childNodes.length; i++) {
            if (editableDiv.childNodes[i].nodeType == Node.TEXT_NODE) {
              if (editableDiv.childNodes[i].length <= charCount) {
                charCount -= editableDiv.childNodes[i].length;
              } else {
                break;
              }
            } else if (
              editableDiv.childNodes[i].nodeType == Node.ELEMENT_NODE
            ) {
              if (charCount > 0) {
                charCount -= 1;
              } else {
                break;
              }
            }
            childNodeIndex += 1;
          }
        }
        if (editableDiv.childNodes[childNodeIndex]) {
          range.setStart(editableDiv.childNodes[childNodeIndex], charCount);
          range.setEnd(editableDiv.childNodes[childNodeIndex], charCount);
        } else {
          range.setStart(editableDiv.lastChild, editableDiv.lastChild.length);
          range.setEnd(editableDiv.lastChild, editableDiv.lastChild.length);
        }
        sel.removeAllRanges();
        sel.addRange(range);
      }
    },
    getNextLazyLoadArray(array, loadedAmount, perCount) {
      const arrayCount = array.length;
      if (arrayCount == 0) return [];
      if (arrayCount <= loadedAmount) return [];
      if (loadedAmount + perCount <= arrayCount)
        return array.slice(loadedAmount, perCount + loadedAmount);
      else {
        return array.slice(loadedAmount);
      }
    },
    async initPageState() {
      //NOTE this should be called to reset the app wide states e.g. appExapanded ... etc
      if (this.$store.state.app_store.appExpanded) {
        await this.$store.dispatch("app_store/toggleExpand", false);
      }
      return Promise.resolve(true);
    },
    file2MD5(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();

        reader.onload = (e) => {
          const result = e.target.result;
          var hash = CryptoJS.MD5(CryptoJS.enc.Latin1.parse(result));
          var md5 = hash.toString(CryptoJS.enc.Hex);
          resolve(md5);
        };
        reader.readAsBinaryString(file);
      });
    },
    generateNewId() {
      return Date.now().toString(36) + Math.random().toString(36).substring(2);
    },
    arrayDifference(arr1, arr2) {
      return arr1.filter((x) => !arr2.includes(x));
    },
    arraySymmetricDifference(arr1, arr2) {
      return arr1
        .filter((x) => !arr2.includes(x))
        .concat(arr2.filter((x) => !arr1.includes(x)));
    },
    htmlEscape(rawStr) {
      return rawStr.replace(
        // eslint-disable-next-line no-useless-escape
        /[\u00A0-\u9999<>\&]/g,
        (i) => "&#" + i.charCodeAt(0) + ";"
      );
    },
    htmlUnescape(encodedHtml) {
      var decodeHtml = document.createElement("textarea");
      decodeHtml.innerHTML = encodedHtml;
      return decodeHtml.value;
    },
    isEmptyOjb(obj) {
      for (var prop in obj) {
        if (Object.hasOwn(obj, prop)) return false;
      }
      return true;
    },
    compareArrays(a, b) {
      const result = JSON.stringify(a) === JSON.stringify(b);
      return result;
    },
    /**
     * For new ux if we need to get the dial code from the country code
     * @param {String} code -> Country code e.g. US/CA/NZ
     * @returns Country dial code e.g. 1/1/64
     */
    fromCodeToDialCode(code) {
      return Countries.filter((item) => {
        if (item.code === code) {
          return item.dial_code;
        }
      })[0].dial_code;
    },
    /**
     * For new ux if we need to get the country code from the member location(country name)
     * @param {String} memberLocation -> member location e.g. Unites States/New Zealand
     * @returns Country code e.g. US/NZ
     */
    fromMemberLocationToCode(memberLocation) {
      return Countries.filter((item) => {
        if (item.name === memberLocation) {
          return item.code;
        }
      })[0].code;
    },
    /**
     * For new ux if we need to get the member location(country name) from country code
     * @param {String} code -> Country code e.g. US/CA/NZ
     * @returns member location(country name) e.g. Unites States/New Zealand
     */
    fromCodeToMemberLocation(code) {
      return Countries.filter((item) => {
        if (item.code === code) {
          return item.name;
        }
      })[0].name;
    },
    /**
     * For new ux if we need to get the dial code from the member location
     * @param {String} memberLocation -> member location e.g. Unites States/New Zealand
     * @returns dial code e.g. 1/64
     */
    fromMemberLocationToDialCode(memberLocation) {
      return this.fromCodeToDialCode(
        this.fromMemberLocationToCode(memberLocation)
      );
    },
    isInViewport(element) {
      const rect = element.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      );
    }
  }
});
