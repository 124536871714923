import Vue from "vue";
import qs from "qs";
import {
  CHANGE_STATUS_SCHEDULED,
  REPORT_DEFAULT_DATE_FORMAT
} from "~/constants";
const moment = require("moment-timezone");
const clone = require("rfdc")({
  proto: true
});
async function getHtmlFromS3(s3Link) {
  if (!s3Link) return "";
  try {
    const remoteContent = await fetch(s3Link, {
      method: "GET"
    });
    if (remoteContent.ok) {
      // sanitize html from script tags
      const tmpDiv = document.createElement("div");
      const remoteContextString = await remoteContent.text();
      tmpDiv.innerHTML = remoteContextString;
      const allScripts = tmpDiv.getElementsByTagName("script");
      let i = allScripts.length;
      while (i--) {
        allScripts[i].parentNode.removeChild(allScripts[i]);
      }
      return tmpDiv.innerHTML;
    }
  } catch (error) {
    console.error(error);
    return "";
  }
}
export const state = () => ({
  MODEL_API_RETURN_FORMAT: "DD MMMM YYYY [at] kk:mm z",
  EMPTY_NEW_CDB_METADATA: {},
  EMPTY_NEW_CDB_METADATA_INDEX_METRICS_ID_MAP: {},
  changeList: [],
  linkedSecurities: [],
  currentChangeDetail: {},
  selectedChangeMetrics: [],
  loadedChangeList: [],
  loadedFeedList: [],
  changeStatusList: [
    {
      id: 0,
      name: "All"
    },
    {
      id: 1,
      name: "Draft",
      internal_name: "draft"
    },
    {
      id: 2,
      name: "Pending",
      internal_name: "pending"
    },
    {
      id: 3,
      name: "Approved",
      internal_name: "approved"
    },
    {
      id: 4,
      name: "Published",
      internal_name: "published"
    },
    {
      id: 5,
      name: "Scheduled",
      internal_name: CHANGE_STATUS_SCHEDULED
    }
  ],
  recipientOptions: [
    {
      id: "1",
      name: "All",
      internal_name: "all"
    },
    {
      id: "2",
      name: "None",
      internal_name: "none"
    }
  ],
  filteredChangeList: [],
  changeLoading: false,
  changeSorting: false,
  securityModelSheets: [],
  reasonList: [],
  securityPrices: [],
  changeCommentList: [],
  currentChangeReport: {},
  gettingReportUsers: false,
  changeSortOptions: {
    sort_by: "change_title",
    sort_type: "asc"
  },
  changeSelectionOptions: {
    type: "all",
    exclude: []
  },
  filterMobileFeedOptions: {},
  isReplaceChange: false,
  currentSearchSecurityId: "",
  saveReportAfterLoad: false
});
export const getters = {
  getDefaultLinkedSecuritySelectionList: (state, getters, rootState) => {
    const selectedSecurityList = [];
    const unSelectedSecurityList = [];
    for (const tmpSecurity of rootState.security.filteredSecurities) {
      const newBoostrapMultiSelectObj = clone(tmpSecurity);
      const selectedIndex = getters.orderedLinkedSecurities.findIndex(
        (linkedSecurity) =>
          linkedSecurity.security_id &&
          linkedSecurity.security_id === tmpSecurity.value.id
      );
      if (selectedIndex >= 0) {
        // if its in linked list
        selectedSecurityList[selectedIndex] = newBoostrapMultiSelectObj;
      } else {
        unSelectedSecurityList.push(newBoostrapMultiSelectObj);
      }
    }
    return {
      selectedSecurityList,
      unSelectedSecurityList
    };
  },
  getDefaultChangeSelectionList: (state) => {
    const selectedChangeList = [];
    const unSelectedChangeList = [];
    switch (state.changeSelectionOptions.type) {
      case "none":
        for (const tmpChange of state.changeList) {
          const selectedIndex = state.changeSelectionOptions.exclude.findIndex(
            (checkChange) => checkChange === tmpChange.value.id
          );
          if (selectedIndex >= 0) {
            selectedChangeList.push(tmpChange);
          } else {
            unSelectedChangeList.push(tmpChange);
          }
        }
        break;
      default:
        //all
        for (const tmpChange of state.changeList) {
          const selectedIndex = state.changeSelectionOptions.exclude.findIndex(
            (checkChange) => checkChange === tmpChange.value.id
          );
          if (selectedIndex >= 0) {
            unSelectedChangeList.push(tmpChange);
          } else {
            selectedChangeList.push(tmpChange);
          }
        }
        break;
    }

    return {
      selectedChangeList,
      unSelectedChangeList
    };
  },
  getDefaultChangeMetricSelectionList: (state, getters, rootState) => {
    let selectedMetrics = new Array(state.selectedChangeMetrics.length);
    const unselectedMetric = [];
    const getAllId = rootState.filter.changeGroups.find(
      (tmpGroup) => tmpGroup.name === "All"
    )?.id;
    for (const tmpMetric of rootState.filter.changeMetrics) {
      const newBoostrapMultiSelectObj = {
        value: tmpMetric,
        text: `${tmpMetric.name}`
      };
      // if its not all group its always unselected
      if (tmpMetric.group !== getAllId) {
        unselectedMetric.push(newBoostrapMultiSelectObj);
        continue;
      }
      const selectedIndex = state.selectedChangeMetrics.findIndex(
        (defaultSelectedMetric) => defaultSelectedMetric === tmpMetric.id
      );
      if (
        selectedIndex >= 0 &&
        unselectedMetric.findIndex(
          (checkMetric) => checkMetric.value.id === tmpMetric.internal_name
        ) < 0
      ) {
        // if its in linked list
        selectedMetrics.splice(selectedIndex, 1, newBoostrapMultiSelectObj);
      } else {
        unselectedMetric.push(newBoostrapMultiSelectObj);
      }
    }
    selectedMetrics = selectedMetrics.filter((it) => {
      return it !== undefined;
    });
    return {
      selectedMetrics,
      unselectedMetric
    };
  },
  getBootstrapOptions: () => {
    return (tmpArray) =>
      tmpArray.map((tmpChange) => ({
        value: {
          id: tmpChange.change_id,
          name: tmpChange.change_title,
          created_at: tmpChange.change_created_at,
          security_code: tmpChange.security_code
        },
        text: `${tmpChange.change_title || "-"}`
      }));
  },
  getBootstrapReasonOptions: () => {
    return (tmpArray) => {
      const resultArr = tmpArray.map((tmpReason) => ({
        value: {
          id: tmpReason.change_reason_id,
          name: tmpReason.change_reason_name,
          created_at: tmpReason.change_reason_created_at
        },
        text: tmpReason.change_reason_name || "-"
      }));
      resultArr.push({ value: { id: "other", name: "Other" }, text: "Other" });
      return resultArr;
    };
  },
  orderedLinkedSecurities: (state, getters, rootState, rootGetters) => {
    if (
      !state.currentChangeReport.security_report_securities ||
      state.currentChangeReport.security_report_securities.length === 0 ||
      !state.linkedSecurities ||
      state.linkedSecurities.length === 0
    )
      return state.linkedSecurities;

    /*
    const orderedArray = state.currentChangeReport.security_report_securities
      .map((secId) => {
        let foundSecurity = state.linkedSecurities.find(
          (tmpSec) => tmpSec.security_id === secId
        );
        if (foundSecurity)
          rootGetters["cdbSheet/computeFinancialMatrix"](foundSecurity);
      })
      .filter((tmpSec) => tmpSec !== undefined);
     */
    const orderedArray = [];
    for (
      let i = 0;
      i < state.currentChangeReport.security_report_securities.length;
      i++
    ) {
      const secId = state.currentChangeReport.security_report_securities[i];
      const foundSecurity = state.linkedSecurities.find(
        (tmpSec) => tmpSec.security_id === secId
      );
      if (foundSecurity)
        orderedArray.push(
          rootGetters["cdbSheet/computeFinancialMatrix"](foundSecurity)
        );
    }
    return orderedArray.length === state.linkedSecurities.length
      ? orderedArray
      : state.linkedSecurities;
  },
  getCurrentReportUser: (state) => {
    const returnObj = {
      amIusingIt: false,
      errorText: ""
    };
    if (
      !state.currentChangeDetail ||
      !state.currentChangeDetail.change_active_members ||
      state.currentChangeDetail.change_active_members.length === 0
    ) {
      return returnObj;
    }
    for (
      let i = 0;
      i < state.currentChangeDetail.change_active_members.length;
      i++
    ) {
      const { member_fullname, member_email, current_member } =
        state.currentChangeDetail.change_active_members[i];
      if (current_member) returnObj.amIusingIt = true;
      // dont add if its own details
      else {
        if (returnObj.errorText !== "")
          returnObj.errorText += `and ${member_fullname} `;
        else returnObj.errorText = `${member_fullname} `;
      }
    }
    if (returnObj.errorText !== "") {
      if (returnObj.errorText.includes(" and ")) {
        returnObj.errorText += "are ";
      } else {
        returnObj.errorText += "is ";
      }
      returnObj.errorText += "editing this report.";
    }
    return returnObj;
  },
  isLocked: (state, getters, rootState, rootGetters) =>
    (state.currentChangeDetail.change_status &&
      state.currentChangeDetail.change_status === CHANGE_STATUS_SCHEDULED) ||
    (state.currentChangeDetail.change_status &&
      state.currentChangeDetail.change_status === "published") ||
    (!getters.getCurrentReportUser.amIusingIt &&
      state.currentChangeDetail.change_active_members &&
      state.currentChangeDetail.change_active_members.length > 0) ||
    (!rootGetters["user/isAuthorised"]("copyedit") &&
      !rootGetters["user/isAuthorised"]("financial") &&
      !rootGetters["user/isAuthorised"]("compliance") &&
      state.currentChangeDetail.change_review_status),

  isUnauthoriseLocked: (state, getters, rootState, rootGetters) =>
    !rootGetters["user/isAuthorised"]("copyedit") &&
    !rootGetters["user/isAuthorised"]("financial") &&
    !rootGetters["user/isAuthorised"]("compliance") &&
    state.currentChangeDetail.change_review_status,
  isEditingLocked: (state, getters) =>
    !getters.getCurrentReportUser.amIusingIt &&
    state.currentChangeDetail.change_active_members &&
    state.currentChangeDetail.change_active_members.length > 0,
  getReportAnalystTime: (state, getters, rootState, rootGetters) => {
    return () => {
      let timeStamp;
      if (
        typeof tinymce !== "undefined" &&
        state.currentChangeDetail.change_status === "published"
      ) {
        const editor =
          tinymce.get("tinymce-report-editor") ||
          tinymce.get("tinymce-published-report-editor");
        if (editor) {
          /*
            If there's a date within the template, we then use this date. So the user can select the date themselves
           */
          const publishedDateEl =
            editor.getDoc().querySelector(".jarden-template-publish-date") ||
            editor.getDoc().querySelector(".publish-date");
          if (publishedDateEl) timeStamp = publishedDateEl.innerHTML;
        }
      }
      if (!timeStamp) {
        const existingDelayedDate = rootGetters[
          "publication_setup/getFormattedDelayedPublicationDate"
        ](state.currentChangeDetail.change_id);
        if (existingDelayedDate != null) {
          timeStamp = existingDelayedDate;
        } else {
          const timezonetouse = getters.getReportAnalystTimeZone();
          timeStamp = moment()
            .tz(timezonetouse)
            .format(REPORT_DEFAULT_DATE_FORMAT);
        }
      }
      return rootGetters["reportTemplate/isEurozTemplate"] ||
        rootGetters["reportTemplate/isMstAccessTemplate"] ||
        rootGetters["reportTemplate/isMstFinancialTemplate"] ||
        rootGetters["reportTemplate/isGeniumTemplate"] ||
        rootGetters["reportTemplate/isOxcapTemplate"]
        ? timeStamp.replace(/ *\([^)]*\) */g, "")
        : timeStamp;
    };
  },
  getReportAnalystTimeZone: (state, getters, rootState, rootGetters) => {
    return () => {
      const location = rootGetters["app_store/objectNestedPropertyCheck"](
        state.currentChangeDetail,
        ["change_analysts", "0", "member_location"],
        "New Zealand"
      );
      const location_shortcode =
        Object.keys(rootState.user.countryCodeToName).find(
          (key) => rootState.user.countryCodeToName[key] === location
        ) || "NZ";
      const timezonearray = moment.tz.zonesForCountry(location_shortcode) || [
        rootState.app_store.DEFAULT_TIMEZONE
      ];
      let timezonetouse;
      if (timezonearray.length === 0)
        timezonetouse = rootState.app_store.DEFAULT_TIMEZONE;
      else {
        timezonetouse = rootState.app_store.DEFAULT_TIMEZONE_ARRAY.find(
          (temp_default_tz) => timezonearray.includes(temp_default_tz)
        );
        if (!timezonetouse) timezonetouse = timezonearray[0];
      }
      return timezonetouse;
    };
  },
  isSettingEmpty: (state) => {
    // if there is no setting will use default so return false
    // if (!state.currentChangeDetail.change_report_template_settings)
    //   return false;
    // for (const tmpKey in state.currentChangeDetail
    //   .change_report_template_settings) {
    //   const tmpStr =
    //     state.currentChangeDetail.change_report_template_settings[tmpKey];
    //   if (tmpStr.length > 0) return false;
    //   else continue;
    // }
    // return true;
    if (
      state.currentChangeDetail.change_report_template_settings &&
      state.currentChangeDetail.change_report_template_settings["front page"]
        ?.length === 0 &&
      state.currentChangeDetail.change_report_template_settings["disclaimer"]
        ?.length === 0
    )
      return true;
    else return false;
  },
  hasExternalModelApi: (state, getters, rootState) => (entity_id) => {
    // return false; // use this on prod for now
    // NOTE will need to change this
    // if (rootState.app_store.isDev) return "jarden";
    let returnV = false;
    switch (entity_id) {
      case "ae319a65-2a7b-448e-a9cf-5da3079bba25":
        returnV = "jarden";
        break;
    }
    return returnV;
  },
  getEsgMovement: () => (linkedSecuritiesObj) => {
    if (!linkedSecuritiesObj) {
      console.error("cannot find linked security");
      return false;
    }
    let esgMovement = "";
    if (
      linkedSecuritiesObj["change_security_current_esg_text"] &&
      linkedSecuritiesObj["change_security_esg_text"]
    ) {
      try {
        const originalEsg = parseFloat(
          linkedSecuritiesObj["change_security_current_esg_text"]
        );
        const newEsg = parseFloat(
          linkedSecuritiesObj["change_security_esg_text"]
        );
        const esgDelta = newEsg - originalEsg;
        if (esgDelta) {
          if (linkedSecuritiesObj.model?.model_metadata?.esg?.esg_threshold) {
            const esgThreshold = parseFloat(
              linkedSecuritiesObj.model.model_metadata.esg.esg_threshold
            );
            if (Math.abs(esgDelta) > esgThreshold) {
              // only if the delta is bigger the the threshold do the movment
              esgMovement = esgDelta > 0 ? "+" : "-";
            }
          } else {
            esgMovement = esgDelta > 0 ? "+" : "-";
          }
        }
      } catch (e) {
        console.error(e);
      }
    }
    return esgMovement;
  }
};
export const mutations = {
  resetChangeState(state) {
    // Object.assign(state, getDefaultState());
    Vue.set(state, "linkedSecurities", []);
    Vue.set(state, "currentChangeDetail", {});
    Vue.set(state, "securityModelSheets", []);
    Vue.set(state, "currentChangeReport", {});
    // Vue.set(state, "modelSheetsData", []);
    Vue.set(state, "securityPrices", []);
  },
  newChangeList(state, newList) {
    Vue.set(state, "changeList", newList);
  },
  updateFilteredChangeList(state, list) {
    Vue.set(state, "filteredChangeList", list);
  },
  updateLoadedChangeList(state, list) {
    list.map((obj) => {
      if (obj.price_decimal_places && obj.price_decimal_places == 2) {
        if (
          obj.current_security_price !== "-" &&
          obj.current_security_price !== "None"
        ) {
          obj.current_security_price = parseFloat(obj.current_security_price)
            .toFixed(2)
            .toString();
        }
        if (
          obj.current_security_targetprice !== "-" &&
          obj.current_security_targetprice !== "None"
        ) {
          obj.current_security_targetprice = parseFloat(
            obj.current_security_targetprice
          )
            .toFixed(2)
            .toString();
        }
        if (
          obj.change_security_publication_price !== "-" &&
          obj.change_security_publication_price !== "None"
        ) {
          obj.change_security_publication_price = parseFloat(
            obj.change_security_publication_price
          )
            .toFixed(2)
            .toString();
        }
        if (
          obj.change_security_targetprice !== "-" &&
          obj.change_security_targetprice !== "None"
        ) {
          obj.change_security_targetprice = parseFloat(
            obj.change_security_targetprice
          )
            .toFixed(2)
            .toString();
        }
      }
    });
    Vue.set(state, "loadedChangeList", list);
  },
  updateLoadedFeedList(state, list) {
    Vue.set(state, "loadedFeedList", list);
  },
  // updateSelectChanges(state, idArray) {
  //     Vue.set(state, "selectedChangeIdArray", idArray);
  // },
  // updateSelectedChangeList(state, newList) {
  //     Vue.set(state, "selectedChangeList", newList);
  // },
  addCurrentChangeDetail(state, oneChange) {
    if (
      oneChange.change_report_template_settings &&
      oneChange.change_report_template_settings.length > 1 &&
      typeof oneChange.change_report_template_settings === "string"
    ) {
      oneChange.change_report_template_settings = JSON.parse(
        oneChange.change_report_template_settings.replace(/'/g, '"')
      );
    }
    Vue.set(state, "currentChangeDetail", oneChange);
  },
  updateCurrentChangeDetail(state, newDetails) {
    for (const key in newDetails) {
      if (key === "change_report_template_settings") {
        if (
          newDetails.change_report_template_settings &&
          newDetails.change_report_template_settings.length > 1 &&
          typeof newDetails.change_report_template_settings === "string"
        ) {
          newDetails.change_report_template_settings = JSON.parse(
            newDetails.change_report_template_settings.replace(/'/g, '"')
          );
        }
      }
      Vue.set(state.currentChangeDetail, key, newDetails[key]);
    }
  },
  updateLinkedSecurities(state, newSecurities) {
    Vue.set(state, "linkedSecurities", newSecurities);
  },
  updateSelectedChangeMetrics(state, newList) {
    Vue.set(state, "selectedChangeMetrics", newList);
  },
  toggleChangeLoading(state, trueOrFalse) {
    state.changeLoading = trueOrFalse;
  },
  toggleChangeSorting(state, trueOrFalse) {
    state.changeSorting = trueOrFalse;
  },
  updateSecurityModelSheet(state, newSecurityModel) {
    state.securityModelSheets.push(newSecurityModel);
  },
  updateLinkedSecurityDetail(state, { index, newDetails }) {
    Vue.set(state.linkedSecurities, index, newDetails);
  },
  updateReasonList(state, newList) {
    Vue.set(state, "reasonList", newList);
  },
  updateSecurityPrice(state, newPrice) {
    state.securityPrices.push(newPrice);
  },
  updateChangeCommentList(state, newList) {
    Vue.set(state, "changeCommentList", newList);
  },
  setChangeReport(state, newReport) {
    Vue.set(state, "currentChangeReport", newReport);
  },
  updateChangeDetails(state, newDetails) {
    for (const tmpKey in newDetails) {
      Vue.set(state.currentChangeDetail, tmpKey, newDetails[tmpKey]);
    }
  },
  toggleGettingReportUsers(state, trueOrFalse) {
    Vue.set(state, "gettingReportUsers", trueOrFalse);
  },
  updateSavedSorted(state, { sort_by, sort_type }) {
    if (sort_by) Vue.set(state.changeSortOptions, "sort_by", sort_by);
    if (sort_type) Vue.set(state.changeSortOptions, "sort_type", sort_type);
  },
  updateSavedChanges(state, { type, exclude }) {
    Vue.set(state, "changeSelectionOptions", {
      type,
      exclude
    });
  },
  updateFilterMobileFeedOptions(state, option) {
    Vue.set(state, "filterMobileFeedOptions", option);
  },
  toggleReplaceChange(state, trueOrFalse) {
    Vue.set(state, "isReplaceChange", trueOrFalse);
  },
  updateCurrentSearchSecurityId(state, securityId) {
    Vue.set(state, "currentSearchSecurityId", securityId);
  },
  updateEmptyNewCdbMetaData(state, newObj) {
    Vue.set(state, "EMPTY_NEW_CDB_METADATA", Object.freeze(newObj));
  },
  updateEmptyNewCdbMetaDataMap(state, newMap) {
    Vue.set(
      state,
      "EMPTY_NEW_CDB_METADATA_INDEX_METRICS_ID_MAP",
      Object.freeze(newMap)
    );
  },
  toggleSaveReportAfterLoad(state, trueOrFalse) {
    Vue.set(state, "saveReportAfterLoad", trueOrFalse);
  }
};
export const actions = {
  remoteResetChangeState({ commit }) {
    commit("resetChangeState");
  },
  remoteUpdateCurrentSearchSecurityId({ commit }, securityId) {
    commit("updateCurrentSearchSecurityId", securityId);
  },
  remoteFilterChanges({ commit, rootState, getters }, filterOption) {
    filterOption.change_sort_type = "asc";
    filterOption.change_sort_by = "change_title";
    filterOption.per_page = 0;
    // filterOption.page_offset = 0;
    filterOption.limited_fields = true;

    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/filters`,
        {
          params: filterOption
        }
      )
      .then((r) => {
        const newList = getters.getBootstrapOptions(r.data);
        commit("updateFilteredChangeList", newList);
        return {
          action: "accept",
          message: newList
        };
      })
      .catch((e) => {
        console.error(e);
        switch (e.response.status) {
          case 417:
            return {
              action: "refresh token",
              message: "need to refresh token"
            };
          default:
            return {
              action: "error",
              message: e.response.data
            };
        }
      });
  },
  remoteGetChanges({ commit, rootState, getters }) {
    // change page
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/filters`,
        {
          params: {
            per_page: 0,
            limited_fields: true
          }
        }
      )
      .then((r) => {
        const newList = getters.getBootstrapOptions(r.data);
        commit("newChangeList", newList);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetChangesSelection({ commit, rootState, getters }) {
    // change list page
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/filters`,
        {
          params: {
            per_page: 0,
            limited_fields: true
          }
        }
      )
      .then((r) => {
        const newList = getters.getBootstrapOptions(r.data);
        commit("updateFilteredChangeList", newList);
        // commit("updateSelectedChangeList", newList);
        //NOTE: use the same variable as the change page
        commit("newChangeList", newList);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remotUpdateSavedSorting(
    { rootState, rootGetters, commit },
    { sort_by, sort_type }
  ) {
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/change_list`,
        {
          member_filter_list_options_params: {
            change_sort_by: sort_by,
            change_sort_type: sort_type
          }
        }
      )
      .then((r) => {
        const commitObj = {};
        const new_sort_by = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "change_sort_by"],
          null
        );
        if (new_sort_by) {
          commitObj["sort_by"] = new_sort_by;
        }
        const new_sort_type = rootGetters[
          "app_store/objectNestedPropertyCheck"
        ](r, ["member_filter_list_options_params", "change_sort_type"], null);
        if (new_sort_type) {
          commitObj["sort_type"] = new_sort_type;
        }
        commit("updateSavedSorted", commitObj);
        return true;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  remoteLoadChangeListDetails({ commit, state, getters, rootState }) {
    const perPage = 30;
    if (getters.getDefaultChangeSelectionList.selectedChangeList.length === 0)
      return false;
    const params = {
      per_page: perPage,
      limited_fields: false,
      page_offset: state.loadedChangeList.length
    };
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/filters`,
        {
          params,
          paramsSerializer: (params) => qs.stringify(params)
        }
      )
      .then((r) => {
        commit("updateLoadedChangeList", [
          ...state.loadedChangeList,
          ...r.data
        ]);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUpdateMobileFilterOptions({ commit, state }, options) {
    commit("updateFilterMobileFeedOptions", options);
  },

  async remoteLoadMobileFeedDetails(
    { commit, state, rootState, rootGetters },
    dict
  ) {
    const perPage = 4;
    const params = {
      search_limit: perPage,
      search_offset: dict.isErase ? 0 : state.loadedFeedList.length,
      use_member_publish_security_filter: true,
      publish_type: "all"
    };
    try {
      const r = await this.$axios.$post(
        `/v2/report/filter/${rootState.user.selectedShownEntity}/${rootGetters["user/currentEntity"].member_id}`,
        {
          ...params
        }
      );
      if (r.result && r.message === "done") {
        r.report_data.forEach((data) => {
          data.report_published_at = rootGetters["app_store/getUtcToTzTime"](
            data.report_published_at,
            "DD MMMM YYYY",
            rootState.app_store.API_DATE_FORMAT
          );
        });
        if (dict.isErase) {
          commit("updateLoadedFeedList", r.report_data);
        } else {
          commit("updateLoadedFeedList", [
            ...state.loadedFeedList,
            ...r.report_data
          ]);
        }
        return {
          action: "accept",
          message: r.report_data
        };
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  },
  remoteFilterFeeds({ commit, state, rootState }, filterOption) {
    filterOption["per_page"] = 4;
    filterOption["page_offset"] = state.loadedFeedList.length;
    return this.$axios
      .$get(
        `/v1/change/mobile/entity/${rootState.user.selectedShownEntity}/changes/filters`,
        {
          params: filterOption
        }
      )
      .then((r) => {
        commit("updateLoadedFeedList", r.data);
        return {
          action: "accept",
          message: r.data
        };
      })
      .catch((e) => {
        console.error(e);
        switch (e.response.status) {
          case 417:
            return {
              action: "refresh token",
              message: "need to refresh token"
            };
          default:
            return {
              action: "error",
              message: e.response.data
            };
        }
      });
  },
  remoteLoadPublishedChangeListDetails({ commit, state, getters, rootState }) {
    const perPage = 30;
    if (getters.getDefaultChangeSelectionList.selectedChangeList.length === 0)
      return false;
    const params = {
      per_page: perPage,
      limited_fields: false,
      page_offset: state.loadedChangeList.length,
      change_status: "published",
      change_sort_by: "change_published_at",
      change_sort_type: "desc"
    };
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/filters`,
        {
          params,
          paramsSerializer: (params) => qs.stringify(params)
        }
      )
      .then((r) => {
        commit("updateLoadedChangeList", [
          ...state.loadedChangeList,
          ...r.data
        ]);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUpdateSelectedChangeList({ commit, state, rootState }, newArray) {
    // work out all or none
    const thresholdC = state.changeList.length / 2;
    let allOrNone = "all";
    let ArrayList = newArray.map((changeObj) => changeObj.value.id);
    if (newArray.length > thresholdC) {
      const newArrayList = [];
      for (let i = 0; i < state.changeList.length; i++) {
        if (!ArrayList.includes(state.changeList[i].value.id)) {
          newArrayList.push(state.changeList[i].value.id);
        }
      }
      ArrayList = newArrayList;
    } else {
      allOrNone = "none";
    }
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/change_list`,
        {
          member_filter_list_options_target_type: allOrNone,
          member_filter_list_options_target_ids: ArrayList
        }
      )
      .then((r) => {
        commit("updateSavedChanges", {
          type: r.member_filter_list_options_target_type || "all",
          exclude: r.member_filter_list_options_target_ids || []
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
    // commit("updateSelectedChangeList", newArray);
  },
  remoteRemoveLoadedChangeListDetails({ commit }) {
    commit("updateLoadedChangeList", []);
  },
  remoteRemoveLoadedFeedListDetails({ commit }) {
    commit("updateLoadedFeedList", []);
  },
  remoteAddNewChange({ rootState }) {
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change`,
        {}
      )
      .then((r) => {
        return {
          action: "accept",
          message: r.change_id
        };
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  },
  remoteGetAnotherChangeById({ rootState, commit }, changeId) {
    if (!changeId) return false;
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${changeId}`
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  },
  remoteGetChangeById({ rootState, commit }, changeId) {
    if (!changeId || changeId === "undefined") return false;
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${changeId}`
      )
      .then((r) => {
        commit("addCurrentChangeDetail", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  },
  remoteRemoveNotificationMedia({ commit, rootState, dispatch }, change_id) {
    if (!change_id)
      return {
        action: "error",
        message: "no change id"
      };
    const params = {};
    params["remove_media"] = true;
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/notification-upload`,
        params
      )
      .then((r) => {
        commit("updateCurrentChangeDetail", {
          change_metadata: null
        });
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUploadNotificationMedia(
    { commit, rootState, dispatch },
    { change_id, title, file }
  ) {
    if (!change_id)
      return {
        action: "error",
        message: "no change id"
      };
    const formData = new FormData();
    let type = "";
    if (file && file["type"].split("/")[0]) {
      type = file["type"].split("/")[0];
    }

    if (file) formData.append("notification_file", file);
    if (type) formData.append("notification_file_type", type);
    if (title) formData.append("notification_file_title", title || "");
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/notification-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteReverseNotificationMedia({ commit, rootState, dispatch }, change_id) {
    if (!change_id)
      return {
        action: "error",
        message: "no change id"
      };

    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/notification-revert-to-previous`
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  localUpdateCurrentChange({ commit }, details) {
    commit("updateCurrentChangeDetail", details);
  },
  remoteUpdateCurrentChange(
    { commit, rootState, dispatch },
    { change_id, ...updateDetails }
  ) {
    if (!change_id)
      return {
        action: "error",
        message: "no change id"
      };
    return this.$axios
      .$put(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}`,
        updateDetails
      )
      .then((r) => {
        commit("updateCurrentChangeDetail", r);
        dispatch(
          "log/getChangeActivityLog",
          {
            changeId: change_id
          },
          {
            root: true
          }
        );
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  async remoteUpdateReplacedChange(
    { commit, rootState, dispatch },
    { change_id, ...updateDetails }
  ) {
    if (!change_id)
      return {
        action: "error",
        message: "no change id"
      };
    try {
      const response = await this.$axios.$put(
        `/v2/change/entity_id/${rootState.user.selectedShownEntity}/change/${change_id}/replace_change_data`, //
        updateDetails
      );
      if (response) {
        commit("updateCurrentChangeDetail", response);
        dispatch(
          "log/getChangeActivityLog",
          {
            changeId: change_id
          },
          {
            root: true
          }
        );
        return {
          action: "accept"
        };
      }
    } catch (error) {
      if (error.response.status !== 400) {
        console.error(error);
        return {
          action: "error",
          message: error.response.data.message
        };
      }
      return;
    }
  },
  remoteGetChangeLinkedSecurities({ commit, rootState }, changeId) {
    if (!changeId || changeId === "undefined") return false;
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${changeId}/securities`
      )
      .then((r) => {
        commit("updateLinkedSecurities", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.log(e);
        return false;
      });
  },
  async remoteUpdateLinkingSecurities(
    { state, commit, rootState, rootGetters, dispatch },
    { change_id, securityIds }
  ) {
    const deleteList = state.linkedSecurities.map(
      (tmpSec) => tmpSec.security_id
    );
    const addList = [];
    for (let i = 0; i < securityIds.length; i++) {
      const tmpId = securityIds[i];
      const findIndex = deleteList.findIndex((tmpSec) => tmpSec === tmpId);
      if (findIndex < 0) {
        addList.push(tmpId);
      } else {
        deleteList.splice(findIndex, 1);
      }
    }
    if (deleteList.length > 0) {
      // delete list api, run delete first so that it does not get over the security limit
      await this.$axios
        .$put(
          `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/security/delete`,
          {
            security_ids: deleteList
          }
        )
        .catch((e) => {
          console.error(e);
          throw e;
        });
    }
    const promiseArray = [];
    if (addList.length > 0) {
      // add list api
      promiseArray.push(
        this.$axios.$post(
          `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/security`,
          {
            security_ids: addList
          }
        )
      );
    }
    // update the array in the DB to save the order of the array
    if (
      state.currentChangeDetail.security_report_id &&
      state.currentChangeDetail.security_report_id !== "None"
    ) {
      promiseArray.push(
        dispatch("remoteUpdateReportContent", {
          security_report_id: state.currentChangeDetail.security_report_id,
          change_id,
          security_report_securities: securityIds
        })
      );
    }
    //===
    return Promise.all(promiseArray)
      .then((values) => {
        // new details from the added securities
        const commitList = [];
        for (let i = 0; i < securityIds.length; i++) {
          const newSecurity =
            addList.length > 0 && values[0]
              ? values[0].find(
                  (security) => security.security_id === securityIds[i]
                )
              : false;
          // NOTE: using financial matrix for now until report is updated -- TO BE CHANGE
          if (newSecurity)
            commitList.push(
              rootGetters["cdbSheet/computeFinancialMatrix"](newSecurity)
            );
          else {
            const oldSecurity = state.linkedSecurities.find(
              (security) => security.security_id === securityIds[i]
            );
            //NOTE not running financial matrix as it should already been done
            if (oldSecurity) commitList.push(oldSecurity);
          }
        }
        commit("updateLinkedSecurities", commitList);
        dispatch(
          "log/getChangeActivityLog",
          {
            changeId: change_id
          },
          {
            root: true
          }
        );
      })
      .catch((e) => {
        throw e;
      });
  },
  remoteUpdateSelectedChangeMetrics({ commit, rootState }, newMetrics) {
    return this.$axios
      .$put(
        `/v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/change_list`,
        {
          member_filter_list_options_metrics: newMetrics.map(
            (met) => met.internal_name
          )
        }
      )
      .then((r) => {
        if (r.member_filter_list_options_metrics) {
          commit(
            "updateSelectedChangeMetrics",
            r.member_filter_list_options_metrics
          );
        }
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteAttachModelToSecurity(
    { rootState, dispatch },
    { security_id, change_id }
  ) {
    return this.$axios
      .$post(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/security/${security_id}/model`,
        {
          change_id
        }
      )
      .then((r) => {
        dispatch(
          "log/getChangeActivityLog",
          {
            changeId: change_id
          },
          {
            root: true
          }
        );
        return {
          action: "open new tab",
          message: r.model_id
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteToggleChangeLoading({ commit }, trueOrFalse) {
    commit("toggleChangeLoading", trueOrFalse);
  },
  remoteToggleChangeSorting({ commit }, trueOrFalse) {
    commit("toggleChangeLoading", trueOrFalse);
  },
  remoteGetSecurityModel({ commit, rootState }, { security_id, change_id }) {
    return this.$axios
      .$get(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/security/${security_id}/model`,
        {
          params: {
            change_id: change_id
          }
        }
      )
      .then((r) => {
        commit("updateSecurityModelSheet", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetModelSheetData(
    { commit, rootState, state },
    { model_sheet_id, tabIndex, sheetIndex, new_model_metadata }
  ) {
    return this.$axios
      .$get(
        `/v1/security_model/entity/${rootState.user.selectedShownEntity}/modelsheet/${model_sheet_id}`,
        {
          params: {
            cdb_formatted_data: true
          }
        }
      )
      .then((r) => {
        if (tabIndex !== null && sheetIndex !== null) {
          const tmpSecurityObj = clone(state.linkedSecurities[tabIndex]);
          tmpSecurityObj.model.model_sheets[sheetIndex].model_sheet_updated_at =
            r.model_sheet_updated_at;
          tmpSecurityObj.change_security_new_cdb.model_sheet_metadata =
            r.model_sheet_metadata;
          if (r.fy_options)
            tmpSecurityObj.change_security_new_cdb.fy_options = r.fy_options;
          if (r.rollover_financial_years)
            tmpSecurityObj.rollover_financial_years =
              r.rollover_financial_years;
          /* NOTE do this prior with api calls
          if (new_model_metadata.additional_info) {
            // info passed from other tab that we dont really store in db
            if (
              new_model_metadata.additional_info.change_security_recommendation
            )
              tmpSecurityObj.change_security_recommendation =
                new_model_metadata.additional_info.change_security_recommendation;
            if (new_model_metadata.additional_info.change_security_targetprice)
              tmpSecurityObj.change_security_targetprice =
                new_model_metadata.additional_info.change_security_targetprice;
          }
          */
          // update model_metadata
          const tmpKeys = Object.keys(new_model_metadata);
          if (!tmpSecurityObj.model) tmpSecurityObj.model = {};
          if (!tmpSecurityObj.model.model_metadata)
            tmpSecurityObj.model.model_metadata = {};
          for (let i = 0; i < tmpKeys.length; i++) {
            const tmpKey = tmpKeys[i];
            tmpSecurityObj.model.model_metadata[tmpKey] =
              new_model_metadata[tmpKey];
          }
          //===
          commit("updateLinkedSecurityDetail", {
            index: tabIndex,
            newDetails: tmpSecurityObj
          });
        }
        // commit("updateModelSheetData", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUploadReportContent(
    { rootState, dispatch, commit, state },
    { change_id, ...reportContent }
  ) {
    if (!change_id || ["none", "undefined"].includes(change_id))
      return {
        action: "error",
        message: "no change id"
      };
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/report`,
        reportContent
      )
      .then((r) => {
        if (
          (!state.currentChangeDetail.security_report_id ||
            state.currentChangeDetail.security_report_id === "None") &&
          r.security_report_id &&
          r.security_report_id !== "None"
        ) {
          // report is created then need to update the id if dont have it already
          commit("updateCurrentChangeDetail", {
            security_report_id: r.security_report_id
          });
        }
        dispatch(
          "log/getChangeActivityLog",
          {
            changeId: change_id
          },
          {
            root: true
          }
        );
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  async remoteGetReportContent(
    { commit, state, rootState, getters },
    security_report_id
  ) {
    if (!security_report_id)
      return {
        action: "error",
        message: "no security report"
      };
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/report/${security_report_id}`
      )
      .then(async (r) => {
        r.security_report_content = await getHtmlFromS3(
          r.security_report_content
        );
        if (
          state.currentChangeDetail &&
          state.currentChangeDetail.security_report_id === security_report_id
        )
          commit("setChangeReport", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.data ? e.data.message : e
        };
      });
  },
  async remoteGetPublicationContent(
    { commit, state, rootState, getters },
    security_report_id
  ) {
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/report/${security_report_id}`
      )
      .then(async (r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.data ? e.data.message : e
        };
      });
  },
  remoteUpdateReportContent(
    { commit, rootState, dispatch },
    { security_report_id, change_id, ...reportContent }
  ) {
    return this.$axios
      .$put(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/report/${security_report_id}`,
        reportContent
      )
      .then((r) => {
        commit("setChangeReport", r);
        dispatch(
          "log/getChangeActivityLog",
          {
            changeId: change_id
          },
          {
            root: true
          }
        );
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteUpdateChangeSecurity(
    { rootState, dispatch, state, commit },
    { change_id, change_security_id, tabIndex = -1, ...updateDetails }
  ) {
    return this.$axios
      .$put(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/security/${change_security_id}`,
        updateDetails
      )
      .then((r) => {
        dispatch(
          "log/getChangeActivityLog",
          {
            changeId: change_id
          },
          {
            root: true
          }
        );
        if (tabIndex < 0 && updateDetails.security_id) {
          tabIndex = state.linkedSecurities.findIndex(
            (tmpSec) => tmpSec.security_id === updateDetails.security_id
          );
        }
        if (tabIndex > -1) {
          const tmpSecurityObj = clone(state.linkedSecurities[tabIndex]);
          for (const tmpKey in updateDetails) {
            if (Object.prototype.hasOwnProperty.call(tmpSecurityObj, tmpKey)) {
              tmpSecurityObj[tmpKey] = updateDetails[tmpKey];
            }
          }
          commit("updateLinkedSecurityDetail", {
            index: tabIndex,
            newDetails: tmpSecurityObj
          });
          return {
            action: "accept",
            message: r
          };
        }
        return {
          action: "error",
          message: "cant find security in linked list"
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteCreateReason({ rootState }, payload) {
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change`,
        payload
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteDeleteChange({ rootState }, change_id) {
    return this.$axios
      .$delete(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}`
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetSecurityPrice({ state, commit, rootState }, security_id) {
    if (!security_id)
      return {
        action: "error",
        message: "no reference id"
      };
    const foundSecurity = state.securityPrices.find(
      (el) => el.security_id == security_id
    );
    if (foundSecurity)
      return {
        action: "accept",
        message: foundSecurity
      };
    return this.$axios
      .$get(
        `/v1/security/entity/${rootState.user.selectedShownEntity}/security/${security_id}/price`
      )
      .then((r) => {
        commit("updateSecurityPrice", r);
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteGetReasonList({ rootState, commit }) {
    return this.$axios
      .$get(`/v1/change/entity/${rootState.user.selectedShownEntity}/reasons`)
      .then((r) => {
        commit("updateReasonList", r);
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteCreateChangeReason({ rootState }, newReason) {
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/reason`,
        newReason
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
        // return false;
        throw e;
      });
  },
  remoteUpdateChangeReason({ rootState }, changeReasons) {
    const payload = {
      reasons: changeReasons
    };
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$put(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/reason`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        // return false;
        throw e;
      });
  },
  remoteDeleteChangeReason({ rootState }, reason_id) {
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$delete(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/reason/${reason_id}`
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        throw e;
      });
  },
  remoteCreateChangeComment({ rootState }, { change_id, ...payload }) {
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/comment`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetChangeComment({ rootState }, change_id) {
    this.$axios.setHeader("Content-Type", "application/json");
    return this.$axios
      .$get(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/comment`
      )
      .then((r) => {
        return {
          action: "accept",
          message: r
        };
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },

  downloadChangeList({ rootState, state }, payload) {
    payload.metrics = state.selectedChangeMetrics.map(
      (tmpMetric) => tmpMetric.internal_name
    );
    // payload.changes = state.loadedChangeList.map(
    //     (tmpChange) => tmpChange.change_id
    // );
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/download-list`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadChangeRecipients({ rootState, state }, { changeId, ...payload }) {
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/${changeId}/recipients/download-list`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadChangePublicationLog({ rootState, state }, { changeId, ...payload }) {
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/${changeId}/recipients/publication_log`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadChangeWordDocument({ rootState, state }, { changeId, ...payload }) {
    return this.$axios
      .$post(
        `/v2/change/entity_id/${rootState.user.selectedShownEntity}/change/${changeId}/download_word_document`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadChangePDFDocument({ rootState, state }, { changeId, ...payload }) {
    return this.$axios
      .$post(
        `/v2/change/entity_id/${rootState.user.selectedShownEntity}/change/${changeId}/download_pdf_document`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadChangeExcels({ rootState }, { changeId, ...payload }) {
    return this.$axios
      .$post(
        `/v2/change/entity_id/${rootState.user.selectedShownEntity}/change/${changeId}/download_financial_model`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  downloadChangeProfile({ rootState, state }, { changeId, ...payload }) {
    return this.$axios
      .$post(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/changes/${changeId}/recipients/change-profile`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: e.response.data.message
        };
      });
  },
  remoteChangePublish({ rootState }, { change_id, ...payload }) {
    return this.$axios
      .$put(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/publish`,
        payload
      )
      .then((r) => ({
        result: true
      }))
      .catch((e) => {
        console.error(e);
        return {
          result: false
        };
      });
  },
  remoteChangeTestPublish({ rootState }, change_id) {
    return this.$axios
      .$put(
        `/v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/test-publish`
      )
      .then((r) => ({
        result: true
      }))
      .catch((e) => {
        console.error(e);
        return {
          result: false
        };
      });
  },
  remoteUpdateReportUser({ rootState, state, commit }) {
    if (
      !state.currentChangeDetail.change_id ||
      state.currentChangeDetail.change_id === "None"
    )
      return {
        result: false
      };
    commit("toggleGettingReportUsers", true);
    return this.$axios
      .$post(
        `v1/common/entity/${rootState.user.selectedShownEntity}/page/change/${state.currentChangeDetail.change_id}`,
        {
          only_current_member: false
        }
      )
      .then((r) => {
        if (r.member_details) {
          commit("updateChangeDetails", {
            change_active_members: r.member_details
          });
        }
        commit("toggleGettingReportUsers", false);
        return {
          result: true
        };
      })
      .catch((e) => {
        console.error(e);
        commit("toggleGettingReportUsers", false);
        return {
          result: false
        };
      });
  },
  remoteRemoveReportUser({ rootState, state, commit }) {
    if (
      !state.currentChangeDetail.change_id ||
      state.currentChangeDetail.change_id === "None"
    )
      return {
        result: false
      };
    commit("toggleGettingReportUsers", true);
    return this.$axios
      .$put(
        `v1/common/entity/${rootState.user.selectedShownEntity}/page/change/${state.currentChangeDetail.change_id}`,
        {
          member_ids: []
        }
      )
      .then((r) => {
        if (r.member_details) {
          commit("updateChangeDetails", {
            change_active_members: r.member_details
          });
        }
        commit("toggleGettingReportUsers", false);
      })
      .catch((e) => {
        console.error(e);
        commit("toggleGettingReportUsers", false);
        return {
          result: false
        };
      });
  },
  remoteToggleGettingReportUsers({ commit }, trueOrFalse) {
    commit("toggleGettingReportUsers", trueOrFalse);
  },
  async setUpReportWebworker({ commit, rootState, state, dispatch }) {
    if (!rootState.webworkers.reportWorker)
      return Promise.reject(new Error("no worker"));
    rootState.webworkers.reportWorker.onmessage = async (event) => {
      const newDetails = {};
      switch (event.data.action) {
        case "got report users":
          if (
            event.data.data.change_status &&
            state.currentChangeDetail.change_status &&
            event.data.data.change_status !=
              state.currentChangeDetail.change_status
          ) {
            // if some one has changed the status because they didnt lock the report reload page
            window.location.reload();
            return;
          }
          if (
            event.data.data.change_planned_publication &&
            state.currentChangeDetail.change_planned_publication &&
            event.data.data.change_planned_publication !=
              state.currentChangeDetail.change_planned_publication
          ) {
            // if some one else has planned publication because they didnt lock the report reload page
            window.location.reload();
            return;
          }
          if (event.data.data.member_details) {
            newDetails["change_active_members"] =
              event.data.data.member_details;
          }
          newDetails["change_review_status"] =
            event.data.data.change_review_status;
          newDetails["change_reviewed_by"] = event.data.data.change_reviewed_by;
          commit("updateChangeDetails", newDetails);
          if (state.gettingReportUsers)
            commit("toggleGettingReportUsers", false);
          break;
        /*
					case "got current change":
					  if (event.data) {
					    commit("updateChangeDetails", {
					      change_review_status: event.data.data.change_review_status,
					      change_reviewed_by: event.data.data.change_reviewed_by,
					    });
					  }
					  break;
					*/
        case "require setup":
          await dispatch("reportWebworkerActions", {
            action: "set fetch credentials",
            baseUrl: this.$axios.defaults.baseURL,
            token: this.$axios.defaults.headers.common.Authorization,
            entity_id: rootState.user.selectedShownEntity || "",
            section_id: state.currentChangeDetail.change_id || ""
          });
          dispatch("reportWebworkerActions", {
            action: event.data.originalRequest
          });
          //NOTE unlock so the next interval will run
          if (state.gettingReportUsers)
            commit("toggleGettingReportUsers", false);
          break;
        case "need refresh token":
          // NOTE dont refresh token from worker as this is handled in the setInterval on change page
          /*
					await dispatch("user/refreshToken", null, { root: true });
					await dispatch("reportWebworkerActions", {
					    action: "set fetch credentials",
					    baseUrl: this.$axios.defaults.baseURL,
					    token: this.$axios.defaults.headers.common.Authorization,
					    entity_id: rootState.user.selectedShownEntity || "",
					    section_id: state.currentChangeDetail.change_id || "",
					});
					dispatch("reportWebworkerActions", {
					    action: event.data.originalRequest
					});
					*/
          //NOTE unlock so the next interval will run
          if (state.gettingReportUsers)
            commit("toggleGettingReportUsers", false);
          break;
      }
    };
    return Promise.resolve(true);
  },
  reportWebworkerActions({ rootState }, payload) {
    rootState.webworkers.reportWorker.postMessage(payload);
    return Promise.resolve(true);
  },
  remoteUnlockReport({ rootState, getters, state, commit }) {
    if (
      !state.currentChangeDetail.change_id ||
      state.currentChangeDetail.change_id === "None"
    )
      return {
        result: false
      };
    commit("toggleGettingReportUsers", true);
    return this.$axios
      .$post(
        `v1/common/entity/${rootState.user.selectedShownEntity}/page/change/${state.currentChangeDetail.change_id}`,
        {
          only_current_member: true
        }
      )
      .then((r) => {
        if (r.member_details) {
          commit("updateChangeDetails", {
            change_active_members: r.member_details
          });
        }
        commit("toggleGettingReportUsers", false);
        return {
          result: true
        };
      })
      .catch((e) => {
        console.error(e);
        commit("toggleGettingReportUsers", false);
        return {
          result: false
        };
      });
  },
  remoteGetSavedFilters({ rootState, commit, rootGetters }) {
    // NOTE api in member namespace but is for all 3 pages
    return this.$axios
      .$get(
        `v1/member/${rootState.user.selectedShownEntity}/member_filter_list_page/change_list`
      )
      .then((r) => {
        if (r.member_filter_list_options_metrics) {
          commit(
            "updateSelectedChangeMetrics",
            r.member_filter_list_options_metrics
          );
        }
        const commitObj = {};
        const sort_by = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "change_sort_by"],
          null
        );
        if (sort_by) {
          commitObj["sort_by"] = sort_by;
        }
        const sort_type = rootGetters["app_store/objectNestedPropertyCheck"](
          r,
          ["member_filter_list_options_params", "change_sort_type"],
          null
        );
        if (sort_type) {
          commitObj["sort_type"] = sort_type;
        }
        commit("updateSavedSorted", commitObj);
        commit("updateSavedChanges", {
          type: r.member_filter_list_options_target_type || "all",
          exclude: r.member_filter_list_options_target_ids || []
        });
      })
      .catch((e) => {
        console.error(e);
        // throw e;
      });
  },
  remoteGetLastReportRecipients({ rootState }, change_id) {
    return this.$axios
      .get(
        `v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/recipients`
      )
      .then((r) => {
        return r.data || [];
      })
      .catch((e) => {
        console.error(e);
        return [];
      });
  },
  remoteRedistributeChange(
    { rootState },
    { newIdList, memberList, change_id }
  ) {
    const maxCount = memberList.length;
    const payload = {
      recipient_type: "none",
      recipient_ids: newIdList
    };
    if (newIdList.length > maxCount / 2) {
      payload.recipient_type = "all";
      payload.recipient_ids = memberList
        .filter((it) => !newIdList.includes(it.value.id))
        .map((it) => it.value.id);
    }
    return this.$axios
      .post(
        `v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/redistribute`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        return false;
      });
  },
  remoteGetReportPresignedUrl({ rootState }, security_report_id) {
    return this.$axios
      .$get(
        `v1/change/entity/${rootState.user.selectedShownEntity}/report/${security_report_id}/upload`
      )
      .then((r) => {
        return r[0];
      })
      .catch((e) => {
        console.error(e);
        return {};
      });
  },
  remoteGetReplacePdfPresignedUrl({ rootState }, change_id) {
    return this.$axios
      .$get(
        `v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/upload-pdf`
      )
      .then((r) => {
        return r[0];
      })
      .catch((e) => {
        console.error(e);
        return {};
      });
  },
  remoteGenerateS3Report(
    { commit, rootState },
    { security_report_id, pdfOption }
  ) {
    return this.$axios
      .$post(
        `v1/change/entity/${rootState.user.selectedShownEntity}/report/${security_report_id}/generate-pdf`,
        pdfOption
      )
      .then(async (r) => {
        r.security_report_content = await getHtmlFromS3(
          r.security_report_content
        );
        commit("setChangeReport", r);
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  remoteGeneratePdf({ commit, rootState }, payload) {
    return this.$axios
      .$post(
        `v2/generate_pdf/${rootState.user.selectedShownEntity}/html_to_pdf`,
        payload
      )
      .then((r) => {
        // r.currentChangeReport.security_report_content = await getHtmlFromS3(
        //   r.security_report_content
        // );
        // commit("setChangeReport", r);
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  remoteGeneratePdfWithWatermark(
    { rootState },
    { entity_id, member_id, payload }
  ) {
    return this.$axios
      .$post(
        `v2/generate_pdf/${entity_id}/${member_id}/generate_member_change_report`,
        payload
      )
      .then((r) => {
        return r;
      })
      .catch((e) => {
        console.error(e);
      });
  },
  async remoteGetReportImgPresignedUrl(
    { rootState },
    { change_id, security_report_id, image_count }
  ) {
    return this.$axios
      .$get(
        `v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/report/${security_report_id}/upload-image/${image_count}`
      )
      .then((r) => {
        return r[0];
      })
      .catch((e) => {
        console.error(e);
        return {};
      });
  },
  async remoteGetContentFromUrl(ctx, url) {
    return await getHtmlFromS3(url);
  },
  remoteToggleReplaceChange({ commit }, trueOrFalse) {
    commit("toggleReplaceChange", trueOrFalse);
  },
  async remoteUpdateAnalystsAttestation(
    { rootState },
    { change_id, ...payload }
  ) {
    return this.$axios
      .$put(
        `v1/change/entity/${rootState.user.selectedShownEntity}/change/${change_id}/attest-change`,
        payload
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {};
      });
  },
  async remoteUpdatePublishedChange({ rootState }, change_id) {
    return this.$axios
      .$put(
        `v2/change/entity_id/${rootState.user.selectedShownEntity}/change/${change_id}/update_published_change_note`
      )
      .then((r) => {
        return true;
      })
      .catch((e) => {
        console.error(e);
        return {};
      });
  },
  async remoteUpdateMultipleSecurities(
    { commit, state, rootState },
    securitiesArray
  ) {
    const arrayLen = securitiesArray.length;
    if (arrayLen === 0 || typeof securitiesArray !== "object")
      return Promise.resolve(true);
    const tmpLinkedSecurities = clone(state.linkedSecurities);
    const changeDetailUpdatePromises = [];
    const priceAndRecommendationArray = [
      "change_security_targetprice",
      "change_security_recommendation",
      "change_security_esg_text"
    ];
    const priceAndRecommendationArrayLen = priceAndRecommendationArray.length;
    for (let i = 0; i < arrayLen; i++) {
      const { security_arr_index, new_linked_sec_details } = securitiesArray[i];
      if (security_arr_index < 0) continue;
      if (!new_linked_sec_details) {
        // TO BE IMPLEMENTED - diapatch remoteGetModelSheetData to get new data
      }
      const tmpLinkedSecurity = tmpLinkedSecurities[security_arr_index];
      // check if there are price and recommendation update
      const priceAndRecommentdationUpdate = {};
      for (let j = 0; j < priceAndRecommendationArrayLen; j++) {
        const attrToCheck = priceAndRecommendationArray[j];
        if (
          tmpLinkedSecurity[attrToCheck] !== new_linked_sec_details[attrToCheck]
        )
          priceAndRecommentdationUpdate[attrToCheck] =
            new_linked_sec_details[attrToCheck];
      }
      if (Object.keys(priceAndRecommentdationUpdate).length > 0) {
        try {
          const updatePromise = this.$axios.$put(
            `/v1/change/entity/${rootState.user.selectedShownEntity}/security/${tmpLinkedSecurity.change_security_id}`,
            priceAndRecommentdationUpdate
          );
          // will check recommendation and ratings in a non-await format so its none blocking - both will be in the cdb as text but need to match to the list of rating or recommmendation
          changeDetailUpdatePromises.push(updatePromise);
        } catch (e) {
          console.error(e);
        }
      }
      //===
      tmpLinkedSecurities[security_arr_index] = new_linked_sec_details;
    }
    if (changeDetailUpdatePromises.length > 0) {
      try {
        await Promise.allSettled(changeDetailUpdatePromises);
      } catch (e) {
        console.error(e);
      }
    }
    commit("updateLinkedSecurities", tmpLinkedSecurities);
    return Promise.resolve(true);
  },
  async setupDefaultNewCdbData({ commit, state }) {
    if (state.linkedSecurities.length === 0)
      return Promise.resolve("no securities");
    const oneSec = state.linkedSecurities.find(
      (tmpSec) =>
        tmpSec.change_security_new_cdb &&
        tmpSec.change_security_new_cdb.model_sheet_metadata &&
        Object.keys(tmpSec.change_security_new_cdb.model_sheet_metadata)
          .length > 0
    );
    if (!oneSec) return Promise.reject("no valid security with cdb data");
    const defaultNewCdb = clone(
      oneSec.change_security_new_cdb.model_sheet_metadata
    );
    const cdbLen = defaultNewCdb.length;
    const newCdbMap = {};
    for (let i = 0; i < cdbLen; i++) {
      const tmpMetricObj = defaultNewCdb[i];
      if (tmpMetricObj.metrics_id) newCdbMap[tmpMetricObj.metrics_id] = i;
      if (tmpMetricObj.op_sheet_row_values) {
        // reset all values to -
        for (const tmpKey in tmpMetricObj.op_sheet_row_values) {
          tmpMetricObj.op_sheet_row_values[tmpKey] = "-";
        }
      }
    }
    commit("updateEmptyNewCdbMetaData", defaultNewCdb);
    commit("updateEmptyNewCdbMetaDataMap", newCdbMap);
    return Promise.resolve(true);
  },
  async remoteGetSecurityPricingHistoryChart({ rootState }, securityId) {
    try {
      const r = await this.$axios.$get(
        `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/link_to/${securityId}/type/security/group/history-chart/limit/1`
      );
      if (!r || r.length === 0) throw new Error("no graph url");
      return {
        action: "accept",
        message: r
      };
    } catch (e) {
      console.error(e);
      /*
      const MOCK_R = [
        {
          reference_id: "9c12d28c-42f2-44b3-a579-851f9c5fd916",
          batch_id: "None",
          entity_id: "ae319a65-2a7b-448e-a9cf-5da3079bba25",
          link_to: "f2962d56-3092-468b-8336-54fd48074af4",
          reference_title: "QBE.AX history chart for 2023-01-20",
          reference_original_file_name:
            "history_chart_f2962d56-3092-468b-8336-54fd48074af4_2018-01-21_2023-01-21_20230120013210.png",
          link_to_type: "security",
          reference_group: "history-chart",
          reference_group_selected: false,
          reference_status: "active",
          reference_order: 0,
          reference_created_at: "2023-01-20 01:32:11.712777",
          reference_file_hotlink:
            "https://www.dev.ranos.io/hotlink/9c12d28c-42f2-44b3-a579-851f9c5fd916",
          reference_file_cdn_link:
            "https://reference.dev.ranos.io/ae319a65-2a7b-448e-a9cf-5da3079bba25/f2962d56-3092-468b-8336-54fd48074af4/charts/history_chart_f2962d56-3092-468b-8336-54fd48074af4_2018-01-21_2023-01-21_20230120013210.png",
          thumbnail_cdn_link: null,
        },
      ];
      */
      return {
        action: "error",
        message: e.message
      };
    }
    // return this.$axios
    //   .$get(
    //     `/v2/reference_file/entity/${rootState.user.selectedShownEntity}/link_to/${securityId}/type/security/group/history-chart/limit/1`
    //   )
    //   .then((r) => {
    //     if (!r) {
    //       throw new Error("no graph results");
    //     }
    //     return {
    //       action: "accept",
    //       message: r,
    //     };
    //   })
    //   .catch((e) => {
    //     console.error(e);
    //     [>
    //     const MOCK_R = [
    //       {
    //         reference_id: "9c12d28c-42f2-44b3-a579-851f9c5fd916",
    //         batch_id: "None",
    //         entity_id: "ae319a65-2a7b-448e-a9cf-5da3079bba25",
    //         link_to: "f2962d56-3092-468b-8336-54fd48074af4",
    //         reference_title: "QBE.AX history chart for 2023-01-20",
    //         reference_original_file_name:
    //           "history_chart_f2962d56-3092-468b-8336-54fd48074af4_2018-01-21_2023-01-21_20230120013210.png",
    //         link_to_type: "security",
    //         reference_group: "history-chart",
    //         reference_group_selected: false,
    //         reference_status: "active",
    //         reference_order: 0,
    //         reference_created_at: "2023-01-20 01:32:11.712777",
    //         reference_file_hotlink:
    //           "https://www.dev.ranos.io/hotlink/9c12d28c-42f2-44b3-a579-851f9c5fd916",
    //         reference_file_cdn_link:
    //           "https://reference.dev.ranos.io/ae319a65-2a7b-448e-a9cf-5da3079bba25/f2962d56-3092-468b-8336-54fd48074af4/charts/history_chart_f2962d56-3092-468b-8336-54fd48074af4_2018-01-21_2023-01-21_20230120013210.png",
    //         thumbnail_cdn_link: null,
    //       },
    //     ];
    //     return {
    //       action: "accept",
    //       message: MOCK_R,
    //     };
    //     */
    //     return {
    //       action: "error",
    //       message: e.message,
    //     };
    //     [>
    //     switch (e.response.status) {
    //       case 417:
    //         return {
    //           action: "refresh token",
    //           message: "need to refresh token",
    //         };
    //         break;
    //       default:
    //         return {
    //           action: "error",
    //           message: e.response.data,
    //         };
    //         break;
    //     }
    //     */
    //   });
  },
  async attachAdditionalSecuritiesToPublishedChange(
    { rootState },
    { changeId, payload }
  ) {
    try {
      const response = await this.$axios.$post(
        `/v2/report/${rootState.user.selectedShownEntity}/${changeId}/attach_security`,
        payload
      );
      if (response.result) {
        return {
          action: "accept",
          message: response
        };
      } else {
        return {
          action: "error",
          message: response
        };
      }
    } catch (error) {
      console.error(error);
      return {
        action: "error",
        message: error
      };
    }
  },
  remoteToggleSaveReportAfterLoad({ commit }, trueOrFalse) {
    commit("toggleSaveReportAfterLoad", trueOrFalse);
  },
  async remoteGenerateTTVForChange({ rootState }, change_id) {
    try {
      const res = await this.$axios.$post(
        `/v2/report/${rootState.user.selectedShownEntity}/change/${change_id}/ttv`
      );
      return {
        status: 200,
        message: res
      };
    } catch (e) {
      console.error(e);
      return e.response;
    }
  },
  async remoteRegenerateTTVForChange({ rootState }, change_id) {
    try {
      await this.$axios.$put(
        `/v2/report/${rootState.user.selectedShownEntity}/change/${change_id}/ttv`
      );
    } catch (e) {
      console.error(e);
      return e.response;
    }
  },
  async checkEsgAndRecommendationList(
    { dispatch, rootState },
    updatedSecurityIds
  ) {
    let hasLinkedExistingRating = false;
    const arrayChecks = [
      {
        general_rating_group: "recommendation",
        change_security_attr: "change_security_recommendation",
        // change_security_cdn_link_attr: "change_security_recommendation_icon_cdn_link",
        // change_security_rating_icons_attr: "change_security_recommendation_icon",
        bespoke_rating_name: "__local_recommendation_",
        list_of_rating: []
      },
      {
        general_rating_group: "esg",
        change_security_attr: "change_security_esg_text",
        // change_security_cdn_link_attr: "change_security_esg_icon_cdn_link",
        // change_security_rating_icon_attr: "change_security_esg_icon",
        bespoke_rating_name: "__local_esg_",
        list_of_rating: []
      }
    ];
    // get the list of recommendations
    for (let i = 0; i < arrayChecks.length; i++) {
      const { general_rating_group } = arrayChecks[i];
      await dispatch(
        "security/remoteGetRatingListForEntity",
        general_rating_group,
        { root: true }
      );
      arrayChecks[i].list_of_rating = clone(rootState.security.ratingList);
    }
    const updateOrCreateLink = (
      selectedRatingObj,
      newRatingObj,
      selectedGroup,
      securityObj
    ) => {
      // console.warn(
      //   "DEBUGPRINT[5]: change.js:2647: selectedRatingObj=",
      //   selectedRatingObj
      // );
      // console.warn(
      //   "DEBUGPRINT[6]: change.js:2648: newRatingObj=",
      //   newRatingObj
      // );
      // console.warn(
      //   "DEBUGPRINT[7]: change.js:2648: selectedGroup=",
      //   selectedGroup
      // );
      // console.warn("DEBUGPRINT[8]: change.js:2649: securityObj=", securityObj);
      if (selectedRatingObj && Object.keys(selectedRatingObj).length > 0) {
        // if there is already a selection, up it to bespoke
        const updateParams = {
          general_rating_id: newRatingObj.general_rating_id,
          general_rating_link_id: selectedRatingObj.general_rating_link_id
        };
        dispatch("security/updateRatingLinkForEntity", updateParams, {
          root: true
        });
      } else {
        // create a new link to select it
        const linkPayload = {
          general_rating_group: selectedGroup,
          change_security_id: securityObj.change_security_id,
          general_rating_id: newRatingObj.general_rating_id,
          general_rating_link_to: securityObj.change_security_id
        };
        // not not using await here so ui does not stop
        dispatch("security/createRatingLinkForEntity", linkPayload, {
          root: true
        });
      }
    };
    const updateSecurityLen = updatedSecurityIds.length;
    for (let i = 0; i < updateSecurityLen; i++) {
      const { security_arr_index, new_linked_sec_details } =
        updatedSecurityIds[i];

      if (security_arr_index < 0) continue;
      if (!new_linked_sec_details) {
        continue;
      }
      if (!new_linked_sec_details.change_security_id) {
        console.error(`cannot find CSID for ${security_arr_index}`);
        continue;
      }
      // loop through both esg and recommendation
      for (let j = 0; j < arrayChecks.length; j++) {
        const {
          general_rating_group,
          change_security_attr,
          bespoke_rating_name
        } = arrayChecks[j];

        const ratingToCheck = new_linked_sec_details[change_security_attr]
          ? new_linked_sec_details[change_security_attr]
          : null;
        if (!ratingToCheck || ratingToCheck === "" || ratingToCheck === "-") {
          continue;
        }
        let bespokeRating = await dispatch(
          "security/remoteGetBespokeRatingForEntity",
          {
            general_rating_group: general_rating_group,
            change_security_id: new_linked_sec_details.change_security_id
          },
          {
            root: true
          }
        );
        // add all the bespoke to the array of ratings
        if (bespokeRating && Object.keys(bespokeRating).length > 0) {
          // save bespoke into the list of selection
          arrayChecks[j].list_of_rating.push(bespokeRating);
        } else {
          bespokeRating = false;
        }
        const selectedRating = await dispatch(
          "security/getRatingLinkForEntity",
          {
            change_security_id: new_linked_sec_details.change_security_id,
            general_rating_group: general_rating_group
          },
          {
            root: true
          }
        );
        const existRatingAlready = arrayChecks[j].list_of_rating.find(
          (tmpRating) => {
            return (
              tmpRating.general_rating_text &&
              tmpRating.general_rating_text.toLowerCase().trim() ===
                ratingToCheck.toLowerCase().trim()
            );
          }
        );
        if (!existRatingAlready) {
          // if the rating does not exist then create bespoke and link it
          if (bespokeRating) {
            dispatch(
              "security/updateRatingForEntity",
              {
                general_rating_group: general_rating_group,
                general_rating_name: bespokeRating.general_rating_name,
                general_rating_text: ratingToCheck,
                general_rating_status: "active",
                general_rating_order: 0,
                general_rating_id: bespokeRating.general_rating_id,
                general_rating_icon_modify_flag: false
              },
              {
                root: true
              }
            );
            updateOrCreateLink(
              selectedRating,
              bespokeRating,
              general_rating_group,
              new_linked_sec_details
            );
            // if link to an existing rating need to get linking security again to get the rating cdn
            hasLinkedExistingRating = true;
          } else {
            // create a bespoke one and link it
            const newItem = {
              id: "local-custom",
              general_rating_text: ratingToCheck,
              general_rating_status: "active",
              general_rating_group: general_rating_group,
              general_rating_name: bespoke_rating_name,
              general_rating_order: 0,
              general_rating_link_to: new_linked_sec_details.change_security_id
            };
            const newBespokeRating = await dispatch(
              "security/createRatingForEntity",
              {
                files: {},
                params: newItem
              },
              {
                root: true
              }
            );

            if (
              !newBespokeRating ||
              newBespokeRating.action === "error" ||
              !newBespokeRating.general_rating_id
            ) {
              console.error(
                `could not create rating for ${new_linked_sec_details[change_security_attr]}`,
                bespokeRating
              );
              continue;
            }
            updateOrCreateLink(
              selectedRating,
              newBespokeRating,
              general_rating_group,
              new_linked_sec_details
            );
            // if link to an existing rating need to get linking security again to get the rating cdn
            hasLinkedExistingRating = true;
          }
          continue;
        }
        // if it exist, then will need to get the selected value and check against that
        updateOrCreateLink(
          selectedRating,
          existRatingAlready,
          general_rating_group,
          new_linked_sec_details
        );
        // if link to an existing rating need to get linking security again to get the rating cdn
        hasLinkedExistingRating = true;
      }
    }
    return hasLinkedExistingRating;
  },
  async sendReplacedChangeFiles({ rootState }, { changeId }) {
    try {
      const response = await this.$axios.$post(
        `v2/change/entity_id/${rootState.user.selectedShownEntity}/change/${changeId}/send_replaced_change_files`
      );
      if (response) {
        return {
          action: "accept",
          message: response
        };
      } else {
        return {
          action: "error",
          message: response
        };
      }
    } catch (error) {
      console.error(error);
      return {
        action: "error",
        message: error
      };
    }
  }
};
