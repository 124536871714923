import qs from "qs";
import Vue from "vue";
import { TRANSLATIONS } from "~/constants/translations.js";
import { LOGINCOOKIETRIGGERVALUE } from "~/constants/commonConstants.js";

export const state = () => ({
  userLoading: false,
  showRegister: false,
  selectedShownEntity: "",
  countryCodeToName: Object.freeze({
    AF: "Afghanistan",
    AX: "Aland Islands",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua And Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia",
    BA: "Bosnia And Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Congo, Democratic Republic",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Cote D'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island & Mcdonald Islands",
    VA: "Holy See (Vatican City State)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic Of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle Of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KR: "Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libyan Arab Jamahiriya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia, Federated States Of",
    MD: "Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    AN: "Netherlands Antilles",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory, Occupied",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RE: "Reunion",
    RO: "Romania",
    RU: "Russian Federation",
    RW: "Rwanda",
    BL: "Saint Barthelemy",
    SH: "Saint Helena",
    KN: "Saint Kitts And Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin",
    PM: "Saint Pierre And Miquelon",
    VC: "Saint Vincent And Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome And Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia And Sandwich Isl.",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SJ: "Svalbard And Jan Mayen",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad And Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks And Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UM: "United States Outlying Islands",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela",
    VN: "Viet Nam",
    VG: "Virgin Islands, British",
    VI: "Virgin Islands, U.S.",
    WF: "Wallis And Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe"
  }),
  countryCodeMap: Object.freeze({
    bd: "880",
    be: "32",
    bf: "226",
    bg: "359",
    ba: "387",
    bb: "+1-246",
    wf: "681",
    bl: "590",
    bm: "+1-441",
    bn: "673",
    bo: "591",
    bh: "973",
    bi: "257",
    bj: "229",
    bt: "975",
    jm: "+1-876",
    bv: "",
    bw: "267",
    ws: "685",
    bq: "599",
    br: "55",
    bs: "+1-242",
    je: "+44-1534",
    by: "375",
    bz: "501",
    ru: "7",
    rw: "250",
    rs: "381",
    tl: "670",
    re: "262",
    tm: "993",
    tj: "992",
    ro: "40",
    tk: "690",
    gw: "245",
    gu: "+1-671",
    gt: "502",
    gs: "",
    gr: "30",
    gq: "240",
    gp: "590",
    jp: "81",
    gy: "592",
    gg: "+44-1481",
    gf: "594",
    ge: "995",
    gd: "+1-473",
    gb: "44",
    ga: "241",
    sv: "503",
    gn: "224",
    gm: "220",
    gl: "299",
    gi: "350",
    gh: "233",
    om: "968",
    tn: "216",
    jo: "962",
    hr: "385",
    ht: "509",
    hu: "36",
    hk: "852",
    hn: "504",
    hm: " ",
    ve: "58",
    pr: "+1-787 and 1-939",
    ps: "970",
    pw: "680",
    pt: "351",
    sj: "47",
    py: "595",
    iq: "964",
    pa: "507",
    pf: "689",
    pg: "675",
    pe: "51",
    pk: "92",
    ph: "63",
    pn: "870",
    pl: "48",
    pm: "508",
    zm: "260",
    eh: "212",
    ee: "372",
    eg: "20",
    za: "27",
    ec: "593",
    it: "39",
    vn: "84",
    sb: "677",
    et: "251",
    so: "252",
    zw: "263",
    sa: "966",
    es: "34",
    er: "291",
    me: "382",
    md: "373",
    mg: "261",
    mf: "590",
    ma: "212",
    mc: "377",
    uz: "998",
    mm: "95",
    ml: "223",
    mo: "853",
    mn: "976",
    mh: "692",
    mk: "389",
    mu: "230",
    mt: "356",
    mw: "265",
    mv: "960",
    mq: "596",
    mp: "+1-670",
    ms: "+1-664",
    mr: "222",
    im: "+44-1624",
    ug: "256",
    tz: "255",
    my: "60",
    mx: "52",
    il: "972",
    fr: "33",
    io: "246",
    sh: "290",
    fi: "358",
    fj: "679",
    fk: "500",
    fm: "691",
    fo: "298",
    ni: "505",
    nl: "31",
    no: "47",
    na: "264",
    vu: "678",
    nc: "687",
    ne: "227",
    nf: "672",
    ng: "234",
    nz: "64",
    np: "977",
    nr: "674",
    nu: "683",
    ck: "682",
    xk: "",
    ci: "225",
    ch: "41",
    co: "57",
    cn: "86",
    cm: "237",
    cl: "56",
    cc: "61",
    ca: "1",
    cg: "242",
    cf: "236",
    cd: "243",
    cz: "420",
    cy: "357",
    cx: "61",
    cr: "506",
    cw: "599",
    cv: "238",
    cu: "53",
    sz: "268",
    sy: "963",
    sx: "599",
    kg: "996",
    ke: "254",
    ss: "211",
    sr: "597",
    ki: "686",
    kh: "855",
    kn: "+1-869",
    km: "269",
    st: "239",
    sk: "421",
    kr: "82",
    si: "386",
    kp: "850",
    kw: "965",
    sn: "221",
    sm: "378",
    sl: "232",
    sc: "248",
    kz: "7",
    ky: "+1-345",
    sg: "65",
    se: "46",
    sd: "249",
    do: "+1-809 and 1-829",
    dm: "+1-767",
    dj: "253",
    dk: "45",
    vg: "+1-284",
    de: "49",
    ye: "967",
    dz: "213",
    us: "1",
    uy: "598",
    yt: "262",
    um: "1",
    lb: "961",
    lc: "+1-758",
    la: "856",
    tv: "688",
    tw: "886",
    tt: "+1-868",
    tr: "90",
    lk: "94",
    li: "423",
    lv: "371",
    to: "676",
    lt: "370",
    lu: "352",
    lr: "231",
    ls: "266",
    th: "66",
    tf: "",
    tg: "228",
    td: "235",
    tc: "+1-649",
    ly: "218",
    va: "379",
    vc: "+1-784",
    ae: "971",
    ad: "376",
    ag: "+1-268",
    af: "93",
    ai: "+1-264",
    vi: "+1-340",
    is: "354",
    ir: "98",
    am: "374",
    al: "355",
    ao: "244",
    aq: "",
    as: "+1-684",
    ar: "54",
    au: "61",
    at: "43",
    aw: "297",
    in: "91",
    ax: "+358-18",
    az: "994",
    ie: "353",
    id: "62",
    ua: "380",
    qa: "974",
    mz: "258"
  }),
  tmpChangeDetailPayload: {},
  currentEntityDetail: {},
  entityLegals: [],
  entityLegalMetaData: [
    {
      title: "Platform terms",
      key: "platform",
      header:
        "Edit platform terms of use, privacy policy, disclosures and other items",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed on this platform."
    },
    {
      title: "Email terms",
      key: "email",
      header: "Edit email terms, disclosures, disclaimers and other items.",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed on all system-generated emails. "
    },
    {
      title: "Notification terms",
      key: "notification",
      header:
        "Edit notification terms, disclosures, disclaimers and other items.",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed on all system-generated WhatsApp, Messenger and other notifications."
    },
    {
      title: "Public disclosure terms",
      key: "public_disclosure",
      header: "Edit public terms, disclosures, disclaimers and other items.",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed."
    },
    {
      title: "General report terms (standard)",
      key: "standard_report",
      header: "Edit appendix terms, disclosures, disclaimers and other items.",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed. ",
      other_inputs: [
        {
          key: "standard_report_cover",
          header: "Edit cover terms, disclosures, disclaimers and other items.",
          placeholder:
            "No terms have been added for this entity. Add and edit content to be displayed. "
        },
        {
          key: "standard_report_footer",
          header:
            "Edit footer terms, disclosures, disclaimers and other items.",
          placeholder:
            "No terms have been added for this entity. Add and edit content to be displayed. "
        }
      ]
    },
    {
      title: "General report terms (special)",
      key: "special_report",
      header: "Edit appendix terms, disclosures, disclaimers and other items.",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed.",
      other_inputs: [
        {
          key: "special_report_cover",
          header: "Edit cover terms, disclosures, disclaimers and other items.",
          placeholder:
            "No terms have been added for this entity. Add and edit content to be displayed. "
        },
        {
          key: "special_report_footer",
          header:
            "Edit footer terms, disclosures, disclaimers and other items.",
          placeholder:
            "No terms have been added for this entity. Add and edit content to be displayed. "
        }
      ]
    },
    {
      title: "Personal report terms (standard)",
      key: "analyst"
    },
    {
      title: "Personal report terms (special)",
      key: "analyst_special",
      header: "Edit personal terms, disclosures, disclaimers and other items.",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed."
    },
    {
      title: "Additional report terms (standard)",
      key: "additional_report"
    },
    {
      title: "Additional report terms (special)",
      key: "additional_report_special",
      header:
        "Edit additional terms, disclosures, disclaimers and other items.",
      placeholder:
        "No terms have been added for this entity. Add and edit content to be displayed."
    }
    // {
    //   title: "Investment report terms",
    //   key: "investment-report-terms",
    //   header: "Edit appendix terms, disclosures, disclaimers and other items.",
    //   placeholder:
    //     "No terms have been added for this entity. Add and edit content to be displayed. ",
    // },
  ],
  entityStats: {},
  memberAuthorisations: [],
  isRefreshingToken: false,
  showLoginModal: false,
  ipoAccessMembers: new Set([
    // "48dba544-fc9f-4d1f-9ebb-d0f861f24011",
    // "792abd7b-c7aa-45ad-955d-2162ab4032ac",
    // "9d28f60b-2288-4928-b5b4-488174a545d8"
  ])
});

export const getters = {
  getInvertedCountryCodeMap: (state) =>
    Object.entries(state.countryCodeMap).reduce((ret, entry) => {
      const [key, value] = entry;
      ret[value] = key;
      return ret;
    }, {}),

  isLimitedAccess: (state, getters) => {
    if (!getters.currentEntity) return true;
    // return true;
    if (
      getters.currentEntity.role_name === "Viewer" ||
      getters.currentEntity.role_name === "Security editor"
    ) {
      return true;
    } else {
      return false;
    }
  },
  isEditor: (state, getters, rootState) => {
    if (!getters.currentEntity) return false;
    // return true;
    if (getters.currentEntity.role_name === "Security editor") {
      return true;
    } else {
      return false;
    }
  },
  isMemberEditor: (state, getters, rootState) => {
    if (!getters.currentEntity) return false;
    // return true;
    if (getters.currentEntity.role_name === "Member editor") {
      return true;
    } else {
      return false;
    }
  },
  isViewer: (state, getters) => {
    if (!getters.currentEntity) return false;
    // return true;
    if (getters.currentEntity.role_name === "Viewer") {
      return true;
    } else {
      return false;
    }
  },
  isAuthorised: (state, getters) => {
    return (authorisation, isViewerAlowedPage = false) => {
      if (!getters.currentEntity) return false; // was showing error on refresh
      if (getters.isViewer && !isViewerAlowedPage) return false;
      if (
        state.memberAuthorisations &&
        state.memberAuthorisations.findIndex((it) => it === authorisation) >= 0
      ) {
        return true;
      } else return false;
    };
  },
  userLoggedIn: (state, getters, rootState) => rootState.auth.loggedIn,
  userEntities: (state, getters, rootState) =>
    rootState.auth && rootState.auth.user && rootState.auth.user.entity_data
      ? rootState.auth.user.entity_data.filter(
          (en) =>
            en.entity_status &&
            en.entity_status === "active" &&
            en.role_name !== "Recipient"
        )
      : null,
  userIPOs: (state, getters, rootState) => {
    const activeEntities =
      rootState.auth && rootState.auth.user && rootState.auth.user.entity_data
        ? rootState.auth.user.entity_data.filter(
            (entity) =>
              entity.entity_status &&
              entity.entity_status === "active" &&
              entity.ipo_page &&
              entity.ipo_page !== "none" &&
              entity.ipo_page === "jarden" &&
              state.ipoAccessMembers.has(entity.member_id)
          )
        : null;
    return activeEntities;
  },
  currentEntity: (state, getters, rootState) =>
    rootState.user.selectedShownEntity && getters.userEntities
      ? getters.userEntities.find(
          (en) => en.entity_id === rootState.user.selectedShownEntity
        )
      : null,
  getEntityLegals(state) {
    const returnArray = [];
    for (const metaObj of state.entityLegalMetaData) {
      const returnObj = {
        ...metaObj
      };
      const index = state.entityLegals.findIndex(
        (legalObj) => legalObj.entity_legal_type === metaObj.key
      );
      if (index >= 0) {
        returnObj.entity_legal_id = state.entityLegals[index].entity_legal_id;
        returnObj.entity_legal_name =
          state.entityLegals[index].entity_legal_name;
        returnObj.entity_legal_desc =
          state.entityLegals[index].entity_legal_desc;
        returnObj.entity_legal_metadata =
          state.entityLegals[index].entity_legal_metadata;
      }
      returnArray.push(returnObj);
    }
    return returnArray;
  },
  pageTitleWithEntity: (state, getters) =>
    "RANOS | ".concat(
      getters.currentEntity && getters.currentEntity.entity_name
        ? getters.currentEntity.entity_name
        : ""
    )
};

export const mutations = {
  changeUserLoading(state, trueOrFalse) {
    state.userLoading = trueOrFalse;
  },
  changeShowRegister(state, trueOrFalse) {
    state.showRegister = trueOrFalse;
  },
  selectShownEntity(state, selectedId) {
    state.selectedShownEntity = selectedId;
  },
  updateAuthorisations(state, newList) {
    state.memberAuthorisations = newList;
  },
  setTmpPayload(state, newPayload) {
    Vue.set(state, "tmpChangeDetailPayload", newPayload);
  },
  updateEntity(state, entity) {
    for (const key in entity) {
      Vue.set(state.currentEntityDetail, key, entity[key]);
    }
  },
  updateEntityLegals(state, newList) {
    Vue.set(state, "entityLegals", newList);
  },
  updateEntityLegal(state, { legal_id, legalDetails }) {
    for (var object of state.entityLegals) {
      if (object.entity_legal_id === legal_id) {
        object.entity_legal_desc = legalDetails.entity_legal_desc;
        break;
      }
    }
  },
  updateEntityStats(state, stats) {
    Vue.set(state, "entityStats", stats);
  },
  updateIsRefreshingToken(state, trueOrFalse) {
    Vue.set(state, "isRefreshingToken", trueOrFalse);
  },
  updateShowLoginModal(state, trueOrFalse) {
    Vue.set(state, "showLoginModal", trueOrFalse);
  }
};

export const actions = {
  remoteShowRegister({ commit }, trueOrFalse) {
    commit("changeShowRegister", trueOrFalse);
  },
  async remoteUserLogin(
    { commit },
    { challenge, email, password, remember, login_token, same_entity = false }
  ) {
    commit("changeUserLoading", true);
    let payload = {};
    if (login_token) {
      //login in with token
      payload = {
        login_challenge: challenge,
        login_token,
        remember: remember
      };
    } else {
      //login in with user and pass
      payload = {
        login_challenge: challenge,
        user_name: email, // probably need to change
        user_password: `${password}`,
        remember: remember
      };
    }
    try {
      if (!same_entity) this.$auth.$storage.removeUniversal("selected_entity");
      return this.$axios
        .$post("/login", payload)
        .then((response) => {
          // this.$auth.$storage.removeUniversal("tmp_useremail");
          // this.$auth.$storage.removeUniversal("tmp_password");
          this.$auth.$storage.removeUniversal("tmp_login_challenge");
          this.$auth.$storage.removeUniversal("tmp_user_token");
          this.$auth.$storage.removeUniversal("tmp_login_token");
          commit("changeUserLoading", false);
          return {
            action: "accept",
            message: response.message
          };
        })
        .catch((error) => {
          commit("changeUserLoading", false);
          switch (error.response.status) {
            case 428:
              this.$auth.$storage.setUniversal(
                "tmp_user_token",
                error.response.data.user_token
              );
              return {
                action: "redirect",
                message: "/login/secondauth/"
              };
            case 401:
              // this.$auth.$storage.removeUniversal("tmp_useremail");
              // this.$auth.$storage.removeUniversal("tmp_password");
              this.$auth.$storage.removeUniversal("tmp_login_token");
              return {
                action: "error",
                message:
                  TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.EMAIL.NOT_EXIST
              };
            case 412:
              // this.$auth.$storage.setUniversal("tmp_useremail", email);
              // this.$auth.$storage.setUniversal("tmp_password", password);
              this.$auth.$storage.setUniversal(
                "tmp_login_challenge",
                challenge
              );
              this.$auth.$storage.setUniversal(
                "tmp_user_token",
                error.response.data.verify_token
              );
              this.$auth.$storage.setUniversal(
                "tmp_login_token",
                error.response.data.login_token
              );
              return {
                action: "redirect",
                message: "/login/verifyaccount/"
              };
            case 400:
              return {
                action: "error",
                message:
                  TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.PASSWORD.INCORRECT
              };
            case 403:
              return {
                action: "error",
                message:
                  TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.EMAIL.NOT_VALID
              };
            case 408:
              //auto login token expired
              this.$auth.$storage.removeUniversal("tmp_login_token");
              return {
                action: "login",
                message: "The login token has expired"
              };
            default:
              return {
                action: "error",
                message: error.response.data.message
              };
          }
        });
    } catch (e) {
      console.log(e);
    }
  },
  sendSecondAuthCode({ commit }, verificationCode) {
    commit("changeUserLoading", true);
    return this.$axios
      .$put("/login", {
        user_token: this.$auth.$storage.getUniversal("tmp_user_token"),
        verification_code: verificationCode
      })
      .then((response) => {
        commit("changeUserLoading", false);
        this.$auth.$storage.removeUniversal("tmp_user_token");
        this.$auth.$storage.removeUniversal("tmp_login_token");
        this.$auth.$storage.removeUniversal("tmp_login_challenge");
        return {
          action: "accept",
          message: response.message
        };
      })
      .catch((e) => {
        commit("changeUserLoading", false);
        switch (e.response.status) {
          case 400:
            return {
              action: "error",
              message:
                TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.CODE.INCORRECT
            };
          case 404:
            return {
              action: "error",
              message:
                TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.CODE.INCORRECT
            };
          default:
            return {
              action: "error",
              message: e.response.data.message
            };
        }
      });
  },
  verifyAccount(context, verificationCode) {
    return this.$axios
      .$put("/v1/auth/verify_user", {
        user_token: this.$auth.$storage.getUniversal("tmp_user_token"),
        verification_code: verificationCode
      })
      .then((response) => {
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        switch (e.response.status) {
          case 400:
            return {
              action: "error",
              message:
                TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.CODE.INCORRECT
            };
          case 404:
            return {
              action: "error",
              message:
                TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.CODE.INCORRECT
            };
          default:
            return {
              action: "error",
              message: e.response.data.message
            };
        }
      });
  },
  remoteUserRegister({ commit }, { username, email, mobile, password }) {
    commit("changeUserLoading", true);
    try {
      return this.$axios
        .$post("/v1/users/", {
          user_name: username,
          user_email: email,
          user_mobile_no:
            mobile?.trim()[mobile?.trim().length - 1] === "-" ? "" : mobile,
          user_password: password,
          user_verified_type: "email",
          user_enable_2fa: false
        })
        .then(function (response) {
          commit("changeUserLoading", false);
          return {
            action: "accept",
            message: response.message
          };
        })
        .catch((error) => {
          commit("changeUserLoading", false);
          switch (error.response.status) {
            case 400:
              return {
                action: "error",
                message:
                  error.response.data.errors &&
                  error.response.data.errors.user_name
                    ? TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.NAME.NOT_VALID
                    : TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.EMAIL.IN_USE
              };
            case 412:
              // this.$auth.$storage.setUniversal("tmp_useremail", email);
              // this.$auth.$storage.setUniversal("tmp_password", password);
              // this.$auth.$storage.setUniversal("tmp_login_challenge", challenge);
              this.$auth.$storage.setUniversal(
                "tmp_user_token",
                error.response.data.verify_token
              );
              this.$auth.$storage.setUniversal(
                "tmp_login_token",
                error.response.data.login_token
              );
              return {
                action: "redirect",
                message: "/login/verifyaccount/"
              };
            default:
              return {
                action: "error",
                message: error.response.data.message
              };
          }
        });
    } catch (e) {
      console.log(e);
    }
  },
  remoteCreateEntity({ commit, dispatch }) {
    commit("changeUserLoading", true);
    try {
      return this.$axios
        .$post("/v1/entity/", {})
        .then(function (response) {
          commit("changeUserLoading", false);
          dispatch("remoteGetUserInfo");
          return {
            action: "accept",
            message: response.message
          };
        })
        .catch((error) => {
          commit("changeUserLoading", false);
        });
    } catch (e) {
      console.log(e);
    }
  },
  remoteDeleteEntity({ commit, dispatch, state }) {
    try {
      return this.$axios
        .$delete(`/v1/entity/${state.selectedShownEntity}`)
        .then(function (response) {
          commit("changeUserLoading", false);
          dispatch("remoteGetUserInfo");
          return {
            action: "accept",
            message: response.message
          };
        })
        .catch((error) => {
          commit("changeUserLoading", false);
        });
    } catch (e) {
      console.log(e);
    }
  },
  remoteGetUserInfo({ commit }) {
    commit("changeUserLoading", true);
    try {
      return this.$axios
        .$get("/v1/auth/userinfo")
        .then((res) => {
          this.$auth.setUser(res);
          return {
            action: "accept",
            message: res
          };
        })
        .catch((error) => {
          return {
            action: "error",
            message: error.response.data.message
          };
        });
    } catch (e) {
      console.log(e);
    }
  },
  remoteForgotPassword({ commit }, { email }) {
    commit("changeUserLoading", true);
    try {
      return this.$axios
        .$post("/v1/auth/forgot_password", {
          user_email: email
        })
        .then(function (response) {
          commit("changeUserLoading", false);
          return {
            action: "accept",
            message: TRANSLATIONS.en.ACCOUNT_PAGE.MESSAGES.EMAIL_LINK
          };
        })
        .catch(function (error) {
          commit("changeUserLoading", false);
          switch (error.response.status) {
            case 404:
              return {
                action: "error",
                message:
                  TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.EMAIL.NOT_EXIST_2
              };
            case 400:
              return {
                action: "error",
                message:
                  TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.EMAIL.NOT_EXIST_2
              };
            default:
              return {
                action: "error",
                message: error.response.data.message
              };
          }
        });
    } catch (e) {
      console.log(e);
    }
  },
  remoteResetPassword({ commit }, { token, newPassword }) {
    commit("changeUserLoading", true);
    try {
      return this.$axios
        .$post("/v1/auth/reset_password", {
          reset_token: token,
          user_password: newPassword
        })
        .then(function (response) {
          commit("changeUserLoading", false);
          return {
            action: "accept",
            message: response.message
          };
        })
        .catch(function (error) {
          commit("changeUserLoading", false);
          switch (error.response.status) {
            case 404:
              return {
                action: "error",
                message:
                  TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.PASSWORD.INVALID
              };
            default:
              return {
                action: "error",
                message: error.response.data.message
              };
          }
        });
    } catch (e) {
      console.log(e);
    }
  },
  remoteUpdateUser({ commit }, payload) {
    commit("changeUserLoading", true);
    try {
      if (
        payload["user_mobile_no"] &&
        payload["user_mobile_no"].trim()[
          payload["user_mobile_no"].trim().length - 1
        ] === "-"
      )
        payload["user_mobile_no"] = "";
      return this.$axios
        .$put("/v1/users/", payload)
        .then((response) => {
          const user = { ...this.$auth.user.user_data };
          for (const key in payload) {
            user[key] = payload[key];
          }
          this.$auth.setUser({ ...this.$auth.user, user_data: user });
          commit("changeUserLoading", false);
          this.$auth.$storage.removeUniversal("tmp_user_token");
          return {
            action: "accept",
            message: response.message
          };
        })
        .catch((error) => {
          commit("changeUserLoading", false);
          switch (error.response.status) {
            case 400:
              // Temporary solution
              // TODO: Refactor, error handling requires full refactor. For example to use codes and handle messages on frontend.
              if (error.response.data.message === "Invalid verification code") {
                return {
                  action: "error",
                  message:
                    TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.CODE.INCORRECT
                };
              }
              return {
                action: "error",
                message: error.response.data.message
              };
            case 412:
              commit("setTmpPayload", payload); //dont use cookies so its removed on refresh
              this.$auth.$storage.setUniversal(
                "tmp_user_token",
                error.response.data.user_token
              );
              return {
                action: "redirect",
                message: "login-verifyaccount"
              };
            default:
              this.$auth.$storage.removeUniversal("tmp_user_token");
              return {
                action: "error",
                message: error.response.data.message
              };
          }
        });
    } catch (e) {
      console.log(e);
    }
  },
  remoteResentVerficationCode() {
    return this.$axios.$post("/v1/auth/resend_verification", {
      user_identification:
        this.$auth.$storage.getUniversal("tmp_login_token") ||
        this.$auth.$storage.getUniversal("tmp_user_token") // I added it for verify account while updating user but it does not work still
    });
  },
  getUserAuthorisation({ commit, state, rootState }) {
    commit(
      "updateAuthorisations",
      rootState.auth.user.entity_data?.find(
        (it) => it.entity_id === state.selectedShownEntity
      ).member_authorizations
    );
  },
  remoteGetUserAuthorisation({ commit, state }) {
    return this.$axios
      .$get("/v1/auth/userinfo")
      .then((res) => {
        commit(
          "updateAuthorisations",
          res.entity_data.find(
            (it) => it.entity_id === state.selectedShownEntity
          ).member_authorizations
        );
        return {
          action: "accept",
          message: res
        };
      })
      .catch((error) => {
        return {
          action: "error",
          message: error.response.data.message
        };
      });
  },
  getUser(ctx, token) {
    this.$auth.setToken("ranos", token);
    this.$axios.setHeader("Authorization", token);
    const schemaOptions = this.$auth.ctx.$auth.strategies.ranos.options;
    // const userUrl = !process.client && process.env.NODE_ENV !== 'production' ? schemaOptions.userinfo_endpoint.replace("http://localhost/", "http://nginx:80/") : schemaOptions.access_token_endpoint;
    const userUrl = schemaOptions.access_token_endpoint;
    return this.$axios
      .$get(userUrl, {
        withCredentials: true,
        crossDomain: true
      })
      .then((res) => {
        this.$auth.setUser(res);
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        switch (e.response.status) {
          case 403:
            return {
              action: "refresh"
            };
          default:
            return {
              action: "error",
              message: e.response.data.message
            };
        }
      });
  },
  refreshToken({ commit, state }, rfToken) {
    if (state.isRefreshingToken) {
      return {
        action: "error",
        message: "already refreshing"
      };
    } else {
      commit("updateIsRefreshingToken", true);
    }
    if (!rfToken) {
      rfToken = this.$auth.$storage.getUniversal("_refresh_token.ranos");
      if (!rfToken) {
        commit("updateIsRefreshingToken", false);
        return {
          action: "error",
          message: "no refresh token"
        };
      }
    }
    const schemaOptions = this.$auth.ctx.$auth.strategies.ranos.options;
    // const tokenUrl = !process.client && process.env.NODE_ENV !== 'production' ? schemaOptions.access_token_endpoint.replace("http://localhost/", "http://nginx:80/") : schemaOptions.access_token_endpoint;
    const tokenUrl = schemaOptions.access_token_endpoint;
    this.$axios.setHeader("Authorization", "");
    return this.$axios
      .post(
        tokenUrl,
        qs.stringify({
          client_id: schemaOptions.client_id,
          refresh_token: rfToken
            .replace("Bearer%20", "")
            .replace("Bearer ", "")
            .trim(),
          grant_type: schemaOptions.refresh_token_key
        }),
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded;charset=utf-8"
          }
        }
      )
      .then((res) => {
        if (res.data.access_token) {
          const accessToken =
            schemaOptions.token_type + " " + res.data.access_token;
          this.$auth.setToken("ranos", accessToken);
          this.$axios.setHeader("Authorization", accessToken);
          if (res.data.refresh_token) {
            const refreshToken =
              schemaOptions.token_type + " " + res.data.refresh_token;
            this.$auth.setRefreshToken("ranos", refreshToken);
          }
          localStorage.setItem(LOGINCOOKIETRIGGERVALUE, "true");
          localStorage.removeItem(LOGINCOOKIETRIGGERVALUE);
          return {
            action: "accept",
            token: accessToken
          };
        } else {
          // console.error("refresh error", res);
          return {
            action: "error",
            message: "no access token"
          };
        }
      })
      .catch((e) => {
        console.error(e);
        return {
          action: "error",
          message: "need to re-login"
        };
      })
      .finally(() => {
        commit("updateIsRefreshingToken", false);
      });
    // return this.$axios.$post()
    // console.log(this.$auth.options);
  },
  remoteSelectShownEntity({ commit, dispatch }, selectedId) {
    this.$auth.$storage.setUniversal("selected_entity", selectedId);
    // this.$cookies.remove("model-draft-update");
    commit("member/updateFilteredMemberList", [], { root: true });
    commit("security/updateSecurityFilteredList", [], { root: true });
    commit("rgpt/resetRgptState", true, { root: true });
    commit("selectShownEntity", selectedId);
    dispatch("getUserAuthorisation");
    return dispatch("getEntityById");
  },
  async remoteLogout() {
    this.$cookies.remove("auth._token.ranos");
    this.$cookies.remove("auth._refresh_token.ranos");
    this.$cookies.remove("auth.selected_entity");
    this.$auth.$storage.removeUniversal("_token.ranos");
    this.$auth.$storage.removeUniversal("_refresh_token.ranos");
    this.$auth.$storage.removeUniversal("selected_entity");
    // this.$cookies.remove("model-draft-update");
    this.$auth.setToken("ranos", "");
    this.$axios.setHeader("Authorization", "");
    const idToken = this.$auth.$storage.getUniversal("_id_token.ranos");
    if (!idToken)
      return {
        action: "error",
        message: "no id token"
      }; // something is wrong
    this.$auth.$storage.removeUniversal("_id_token.ranos");
    const schemaOptions = this.$auth.ctx.$auth.strategies.ranos.options;
    const cstate = schemaOptions.state;
    if (!cstate)
      return {
        action: "error",
        message: "no config state"
      }; // something is wrong
    // return this.$axios.get(schemaOptions.logout_endpoint, {
    //     params: {
    //         id_token_hint: idToken,
    //         state: cstate,
    //         post_logout_redirect_uri: "http://localhost/"
    //     }
    // }).then(r => {
    //     console.log(r);
    // }).catch(e => {
    //     console.error(e);
    // });
    // console.log(idToken);

    return {
      action: "redirect",
      message:
        schemaOptions.logout_endpoint +
        "?" +
        qs.stringify({
          id_token_hint: idToken,
          state: cstate,
          post_logout_redirect_uri: this.$auth.options.redirect.logout
        })
    };
  },
  resendTwoFactorCode() {
    this.$axios.$post("/v1/auth/send_2fa", {
      user_token: this.$auth.$storage.getUniversal("tmp_user_token")
    });
  },
  remoteCheckAddMemberToEntityToken(ctx, token) {
    return this.$axios
      .$post("/v1/member/verify_invitation_token", {
        member_entity_token: token
      })
      .then((r) => {
        //this.$auth.$storage.setUniversal("tmp_user_invite_token", token);
        this.$cookies.set("tmp_user_invite_token", token, {
          path: "/",
          maxAge: 60 * 7,
          sameSite: "none",
          secure: true
        });
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        switch (e.response.status) {
          case 408:
            return {
              action: "error",
              message: TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.MEMBER_INVITE
            };
          default:
            return {
              action: "error",
              message: TRANSLATIONS.en.ACCOUNT_PAGE.ERROR_MESSAGES.MEMBER_INVITE
            };
        }
      });
  },
  remoteAcceptInvite({ commit }, token) {
    commit("changeUserLoading", true);
    return this.$axios
      .$post("/v1/member/accept_invitation", {
        member_entity_token: token
      })
      .then((r) => {
        commit("changeUserLoading", false);
        //this.$auth.$storage.removeUniversal("tmp_user_invite_token");
        this.$cookies.remove("tmp_user_invite_token");
        return {
          action: "accept"
        };
      })
      .catch((e) => {
        //this.$auth.$storage.removeUniversal("tmp_user_invite_token");
        this.$cookies.remove("tmp_user_invite_token");
        // dispatch("app_store/remoteUpdateInviteError", true, {;
        //     root: true
        // });
        commit("changeUserLoading", false);
        switch (e.response.status) {
          case 408:
            return {
              action: "error",
              message:
                "The invitation has expired, please ask for a new invitation."
            };
          default:
            return {
              action: "error",
              message: e.response.data.message
            };
        }
      });
  },
  uploadEntityLogo({ commit, rootState, dispatch }, entityLogoPayload) {
    const formData = new FormData();
    if (entityLogoPayload.entity_logo) {
      formData.append("entity_logo", entityLogoPayload["entity_logo"]);
    }
    if (entityLogoPayload.report_logo) {
      formData.append("report_logo", entityLogoPayload["report_logo"]);
    }
    if (entityLogoPayload.notification_logo) {
      formData.append(
        "notification_logo",
        entityLogoPayload["notification_logo"]
      );
    }
    if (entityLogoPayload.notification_positive_icon) {
      formData.append(
        "notification_positive_icon",
        entityLogoPayload["notification_positive_icon"]
      );
    }
    if (entityLogoPayload.notification_negative_icon) {
      formData.append(
        "notification_negative_icon",
        entityLogoPayload["notification_negative_icon"]
      );
    }
    if (entityLogoPayload.report_branding_icon) {
      formData.append(
        "report_branding_icon",
        entityLogoPayload["report_branding_icon"]
      );
    }
    return this.$axios
      .$post(
        `/v1/entity/${rootState.user.selectedShownEntity}/multiple-logo-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        }
      )
      .then((r) => {
        dispatch("getEntityById");
        dispatch("log/getEntityActivityLog", "", {
          root: true
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  updateEntity({ commit, rootState, dispatch }, entity) {
    // entity.entity_status = "active"; //This is required by backend by default.
    return this.$axios
      .$put(`/v1/entity/${rootState.user.selectedShownEntity}`, entity)
      .then((r) => {
        commit("updateEntity", r);
        dispatch("log/getEntityActivityLog", "", {
          root: true
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  getEntityById({ commit, state }) {
    return this.$axios
      .$get(`/v1/entity/${state.selectedShownEntity}`)
      .then((r) => {
        commit("updateEntity", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  sendContactMessage({ commit, state, dispatch }, message) {
    return this.$axios
      .$post(`/v1/entity/${state.selectedShownEntity}`, message)
      .then((r) => {
        dispatch("log/getEntityActivityLog", "", {
          root: true
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteCreateEntityLegal({ commit, state, dispatch }, legal) {
    return this.$axios
      .$post(`/v1/entity/${state.selectedShownEntity}/legal`, legal)
      .then((r) => {
        dispatch("log/getEntityActivityLog", "", {
          root: true
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteUpdateEntityLegal(
    { commit, state, dispatch },
    { entity_legal_id, ...legalDetails }
  ) {
    return this.$axios
      .$put(
        `/v1/entity/${state.selectedShownEntity}/legal/${entity_legal_id}`,
        legalDetails
      )
      .then((r) => {
        commit("updateEntityLegal", {
          legal_id: entity_legal_id,
          legalDetails: legalDetails
        });
        dispatch("log/getEntityActivityLog", "", {
          root: true
        });
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetEntityLegal({ commit, state }) {
    return this.$axios
      .$get(`/v1/entity/${state.selectedShownEntity}/legals`)
      .then((r) => {
        commit("updateEntityLegals", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteGetEntityStats({ commit, state }) {
    return this.$axios
      .$get(`/v1/entity/${state.selectedShownEntity}/entity-stats`)
      .then((r) => {
        commit("updateEntityStats", r);
        return true;
      })
      .catch((e) => {
        console.error(e);
        return false;
      });
  },
  remoteCheckAccessToken({ commit }) {
    return this.$axios
      .$get(`v1/auth/check_token`, { progress: false })
      .then((r) => {
        // commit("updateShowLoginModal", false); // this will remove login modal on the change pag
        return true;
      })
      .catch((e) => {
        console.error(e);
        commit("updateShowLoginModal", true); // this will show login modal on the change pag
        return false;
      });
  },
  remoteToggleLoginModal({ commit }, trueOrFalse) {
    commit("updateShowLoginModal", trueOrFalse);
    return true;
  },
  remoteUpdateToken({ dispatch, commit, state }) {
    dispatch("app_store/remoteToggleAppLoading", true, { root: true });
    commit("updateIsRefreshingToken", true);
    const refreshT = this.$cookies.get("auth._refresh_token.ranos");
    const accessT = this.$cookies.get("auth._token.ranos");
    if (refreshT && accessT) {
      this.$auth.setToken("ranos", accessT);
      this.$axios.setHeader("Authorization", accessT);
      this.$auth.setRefreshToken("ranos", refreshT);
    }
    dispatch("app_store/remoteToggleAppLoading", false, { root: true });
    commit("updateIsRefreshingToken", false);
    commit("updateShowLoginModal", false);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
};
